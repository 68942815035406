import React, {useState, useRef, useEffect, useCallback} from "react";
import store from "../../components/hooks/store";
import {Button} from "devextreme-react/button";
import {SelectBox} from "devextreme-react";
import appInfo from "../../app-info";
import {Tooltip} from "devextreme-react/tooltip";
import {alert, confirm} from "devextreme/ui/dialog";
import {Redirect} from "react-router-dom";
import DataGrid, {
	Column,
	Paging,
	ColumnFixing,
	Editing,
	FilterRow,
	SearchPanel,
	Pager,
	Toolbar,
	Item,
	RemoteOperations,
	Scrolling,
	Selection,
	Export,
	Lookup,
	RequiredRule
} from "devextreme-react/data-grid";
import {exportDataGrid} from "devextreme/excel_exporter";
import {getPermission, setMode, getMode} from "../configuration/helpers/globalFunctions";
import {Workbook} from "exceljs";
import saveAs from "file-saver";
import IconFilter from "../../components/filter.svg";
import {useAuth} from "../../contexts/auth";

export default () => {
	const {user} = useAuth();
	const [disableSearchBtn, setdisableSearchBtn] = useState(true);
	const [disableYear, setDisableYear] = useState(false);
	const [customDataSource, setCustomDataSource] = useState(
		store({
			url: `${appInfo.configURL}AccountCalendar/`
		})
	);
	let token = user ? user.data.token : '';
	// /* Instance components in order to reset them when necessary */
	const yearSelect = useRef(null);
	let validationMsg = "";
	let yearMonthAdded = [];

	const [disableAddBtn, setDisableAddBtn] = useState(true);
	const [yearList, setYearList] = useState(null);
	const [yearSelected, setYearSelected] = useState(null);
	const [yearMonths, setYearMonths] = useState(null);
	const [showTootlTip, setShowTooltip] = useState(true);
	const [disableDeleteAction, setDisableDeleteAction] = useState(false);
	const currentYear = new Date().getFullYear().toString();
	const currentMonth = (new Date().getMonth() + 1).toString();

	useEffect(() => {
		let getYears = `${appInfo.configURL}AccountCalendar/years`;

		(async () => {
			try {
				await fetch(getYears, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`}
				})
					.then((res) => res.json())
					.then((res) => {
						setYearList(res);
					});
			} catch (error) {
				console.warn(error);
			}
		})();
	}, []);
	const dataGrid = useRef(null);
	const allowedPageSizes = [10, 30, 50, "all"];
	const permission = getPermission(user, "ConfigurationMasters", "AccountCalendar");
	let finalMsg = "";
	let canceled;
	let language = user ? user.data.userData.language : '';
	let formatDate = user ? user.data.userData.formatDate : '';

	const [totalCount, setTotalCount] = useState(0);
	const [dataGridSettings, setdataGridSettings] = useState({
		showHeaderFilter: true,
		showPageSizeSelector: true,
		showFilterRow: true,
		showInfo: true,
		showNavButtons: true,
		mode: "View",
		infiniteScroll: "standard"
	});
	const getTotalCount = () => {
		//this goes in onContentReady
		let total = sessionStorage.getItem("totalCount");
		setTotalCount(total);
	};
	const clearFiltersDG = () => {
		dataGrid.current.instance.clearFilter();
	};
	const onExporting = (e) => {
		/* Get today Date First */
		let today = new Date();
		let month = today.getMonth() + 1; //months from 1-12
		let day = today.getDate();
		let year = today.getUTCFullYear();

		let hours = today.getHours();
		let minutes = today.getMinutes();
		let ampm = hours >= 12 ? "pm" : "am";
		hours = hours % 12;
		hours = hours ? hours : 12; // the hour '0' should be '12'
		minutes = minutes < 10 ? "0" + minutes : minutes;
		let fullTime = hours + ":" + minutes + " " + ampm;
		let dateFormatted;
		language === "ES"
			? (dateFormatted = `${day}-${month}-${year}`)
			: (dateFormatted = `${month}-${day}-${year}`);
		const buildTitle = `Account Calendar Report ${dateFormatted}.xlsx`;

		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet(`Account Calendar ${dateFormatted}`);
		exportDataGrid({
			component: e.component,
			worksheet: worksheet,
			topLeftCell: {row: 1, column: 1}
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], {type: "application/octet-stream"}), buildTitle);
			});
		});
		e.cancel = true;
	};
	const onRowInserting = (e) => {
		e.data.yearMonth = parseInt(e.data.yearMonth);
	};
	const onRowInserted = (e) => {
		let localResultMsg = "";
		let message = e.data.data.message;

		if (e.data.statusText == "OK") {
			//e.data.status == 200
			finalMsg !== "" && !finalMsg.indexOf(message) && message !== undefined
				? (localResultMsg += "")
				: (localResultMsg = message);
		} else {
			finalMsg !== "" && !finalMsg.indexOf(message) && message !== undefined
				? (localResultMsg += "" + message)
				: (localResultMsg = message);
		}
		finalMsg += localResultMsg;
	};
	const onEditCanceled = useCallback((e) => {
		setMode("View");
	}, []);
	const onOptionChanged = useCallback((e) => {
		if (e.fullName !== "editing.changes") {
			return;
		}
		if (getMode() === "View") {
			setMode("Edit");
		}
	}, []);
	const onEditCanceling = useCallback((e) => {
		if (canceled) {
			return;
		} else {
			e.cancel = true;
			confirm("Unsaved data exist. <br/> Are you sure you want to cancel?").then((confirm) => {
				if (confirm) {
					canceled = true;
					e.component.cancelEditData();
					canceled = false;
				}
			});
		}
	}, []);
	const generateDefaultValues = async (year) => {
		const dialogResult = await confirm(validationMsg);

		if (dialogResult) {
			let generateValuesURL = `${appInfo.configURL}AccountCalendar/generatevalues?year=${year}`;
			setDisableYear(true);
			await fetch(generateValuesURL, {
				method: "post",
				headers: {Authorization: `Bearer ${token}`}
			}).then((res) => {
				//if res.status == OK then refresh the page with the year selected
				setCustomDataSource(
					store({
						url: `${appInfo.configURL}AccountCalendar?year=${year}`
					})
				);
			});
		} else {
			setDisableYear(true);
			setCustomDataSource(
				store({
					url: `${appInfo.configURL}AccountCalendar?year=${year}`
				})
			);
		}
	};

	const sendReportRequest = async () => {
		setDisableYear(true);
		let validateURL = `${appInfo.configURL}AccountCalendar/validate?year=${yearSelected}`;
		let getYearMonths = `${appInfo.configURL}AccountCalendar/YearMonths?year=${yearSelected}`;

		let validPassed = false;
		if (yearSelected) {
			await fetch(getYearMonths, {
				method: "get",
				headers: {Authorization: `Bearer ${token}`}
			})
				.then((res) => res.json())
				.then((res) => {
					setYearMonths(res);
				});

			await fetch(validateURL, {
				method: "get",
				headers: {Authorization: `Bearer ${token}`}
			})
				.then((res) => res.json())
				.then((res) => {
					if (!res.isValid) {
						validPassed = true;
						if (validationMsg == "") validationMsg = res.message;
					}
				});
			if (validPassed) {
				await generateDefaultValues(yearSelected);
			}
		}
		/////
		setCustomDataSource(
			store({
				url: `${appInfo.configURL}AccountCalendar?year=${yearSelected}`
			})
		);
		setShowTooltip(false);
		/** INFO: --- Business Rule: User cannot enter data in past years */
		if (yearSelected < currentYear) {
			setDisableAddBtn(true);
		} else {
			setDisableAddBtn(false);
		}
	};

	const clearFilters = () => {
		sessionStorage.setItem("totalCount", 0);
		setTotalCount(0);
		yearSelect.current.instance.reset();
		setCustomDataSource(null);
		setDisableYear(false);
		setDisableAddBtn(true);
	};

	const onValueChanged = async (e) => {
		setYearSelected(e.value);
		if (e.value !== null) {
			setdisableSearchBtn(false);
		} else {
			setdisableSearchBtn(true);
		}
		if (e.value !== null && e.value >= currentYear) {
			if (permission && permission.delete) {
				setDisableDeleteAction(false);
			}
		} else {
			setDisableDeleteAction(true);
		}
	};
	const onRowPrepared = (e) => {
		/** INFO: validate Scenario When User try to add new Row and YearMonth Already exist */
		if (e.rowType == "data" && e.isNewRow === true && e.data.yearMonth) {
			/** INFO: Validate Scenario when yearMonth is older month in current year and is not allowed to add manually */
			let yearmonthSelected = e.data.yearMonth;
			let monthSplit = ("" + yearmonthSelected).split("");
			let maxSize = monthSplit.length;

			let monthValue =
				monthSplit[maxSize - 2] === 0
					? `${monthSplit[maxSize - 1]}`
					: `${monthSplit[maxSize - 2]}${monthSplit[maxSize - 1]}`;

			if (yearMonthAdded.length > 0) {
				yearMonthAdded.push(e.data);
			} else {
				let datagridItems = dataGrid.current.instance.getDataSource().items();
				let currentValue = e.data.yearMonth;
				let valueAlreadyExist = datagridItems.some((item) => item.yearMonth == currentValue);

				if (valueAlreadyExist) {
					alert("[20007] Record Already Exist!");
					e.component.cellValue(e.rowIndex, "yearMonth", "");
					e.component.focus();
				} else {
					monthValue = parseInt(monthValue);

					if (monthValue < currentMonth) {
						alert("[20260] Past Calendar Data not allowed to modify.");
						e.component.cellValue(e.rowIndex, "yearMonth", "");
						e.component.focus();
					}
				}
			}
		}

		/* INFO: Validate Scenario when User update start date and is not a new row */
		if (e.rowType == "data" && e.data.startDate && !e.isNewRow) {
			/* INFO: validate user do not enter past years */

			if (e.modifiedValues && e.modifiedValues[2]) {
				let startDate = e.data.startDate;
				let startYear = new Date(startDate).getFullYear();
				let startMonth = new Date(startDate).getMonth(); //This refers to the Month of the new Date that users just select
				let previousMonth = new Date(e.oldData.startDate).getMonth(); // This Refer to the Month value of the previous date

				startYear = startYear.toString();
				if (currentYear === yearSelected && startMonth < previousMonth) {
					alert("[20260] Past Calendar Data not allowed to modify.");
					e.component.cellValue(e.rowIndex, "startDate", "");
					e.component.focus();
				}
				if (startYear !== yearSelected && startMonth < currentYear) {
					/* this condition may work for other scenarios */
					alert("[20260] Past Calendar Data not allowed to modify.");
					e.component.cellValue(e.rowIndex, "startDate", "");
					e.component.focus();
				}
				if (startYear !== yearSelected && startYear < yearSelected) {
					alert("[20260] Past Calendar Data not allowed to modify.");
					e.component.cellValue(e.rowIndex, "startDate", "");
					e.component.focus();
				}
				/* INFO: validate start date is not grather than end date */
			}
		}
		/* INFO: Validate Scenario when User insert a new start date  */
		if (e.rowType == "data" && e.data.startDate && e.isNewRow === true) {
			if (e.values && e.values[2]) {
				let startDate = e.data.startDate;
				let startYear = new Date(startDate).getFullYear();
				startYear = startYear.toString();

				if (startYear !== yearSelected && startYear < currentYear) {
					alert("[20260] Past Calendar Data not allowed to modify.");
					e.component.cellValue(e.rowIndex, "startDate", "");
					e.component.focus();
				}

				if (e.values[2] && e.values[3]) {
					//validate if the start date is grather than end date
					if (e.values[2] > e.values[3]) {
						alert("[20260] Past Calendar Data not allowed to modify.");
						e.component.cellValue(e.rowIndex, "endDate", "");
						e.component.focus();
					}
				}
			}
		}
		/* INFO: Validate Scenario when User update end date  */
		if (e.rowType == "data" && e.data.endDate && e.isEditing === true) {
			if (e.modifiedValues && e.modifiedValues[3]) {
				let endDate = e.data.endDate;
				let endYear = new Date(endDate).getFullYear();
				endYear = endYear.toString();
				if (endYear !== yearSelected && endYear < currentYear) {
					alert("[20260] Past Calendar Data not allowed to modify.");
					e.component.cellValue(e.rowIndex, "endDate", "");
					e.component.focus();
				}
			}
		}
		/* INFO: Validate Scenario when User insert a new end date  */
		if (e.rowType == "data" && e.data.endDate && e.isNewRow === true) {
			if (e.values && e.values[3]) {
				let endDate = e.data.endDate;
				let endYear = new Date(endDate).getFullYear();
				endYear = endYear.toString();
				if (endYear !== yearSelected && endYear < currentYear) {
					alert("[20260] Past Calendar Data not allowed to modify.");
					e.component.cellValue(e.rowIndex, "endDate", "");
					e.component.focus();
				}
			}
		}
		/* INFO: Validate Scenario when User finish to edit some of the dates   */
		if (
			e.rowType == "data" &&
			(e.data.startDate || e.data.endDate) &&
			e.isEditing === false &&
			e.modifiedValues
		) {
			if (e.data.startDate > e.data.endDate) {
				alert("[20263] Start Date can not greater than End Date");
				e.component.cellValue(e.rowIndex, "startDate", "");
			}
		}
	};
	const onEditorPreparing = (e) => {
		if (
			(e.dataField === "yearMonth" ||
				e.dataField === "id" ||
				e.dataField === "startDate" ||
				e.dataField === "endDate") &&
			e.parentType === "dataRow"
		) {
			let rowDataMonth = e.row.data.yearMonth;
			let monthSplit = ("" + rowDataMonth).split("");
			let maxSize = monthSplit.length;

			let monthValue =
				monthSplit[maxSize - 2] === 0
					? `${monthSplit[maxSize - 1]}`
					: `${monthSplit[maxSize - 2]}${monthSplit[maxSize - 1]}`;

			monthValue = parseInt(monthValue);
			if (yearSelected < currentYear) {
				/** Any record older than current year will be disabled */
				e.editorOptions.disabled = true;
			} else {
				e.editorOptions.disabled = false;
			}
			if (monthValue < currentMonth) {
				e.editorOptions.disabled = true;
			}
		}
		if (e.dataField === "yearMonth" && e.parentType === "dataRow") {
			if (yearSelected >= currentYear) {
				e.editorOptions.disabled = !e.row.isNewRow;
			}
		}
		if (e.dataField === "id" && e.parentType === "dataRow") {
			if (yearSelected >= currentYear) {
				e.editorOptions.disabled = true;
			}
		}
	};
	const onRowUpdating = (e) => {
		e.newData = {...e.oldData, ...e.newData};
	};
	const onRowUpdated = (e) => {
		let localResultMsg = "";
		let message = e.data.data.message;
		if (e.data.statusText == "OK") {
			//e.data.status == 200
			finalMsg !== "" && !finalMsg.indexOf(message)
				? (localResultMsg += "")
				: (localResultMsg = message);
		} else {
			finalMsg !== "" && !finalMsg.indexOf(message)
				? (localResultMsg += "")
				: (localResultMsg = message);
		}
		finalMsg += localResultMsg;
	};
	const onRowRemoving = (e) => {
		var changes = e.component.option("editing.changes");
		changes.find((c) => c.key === e.key).key = e.data;
		e.component.option("editing.changes", changes);
	};
	const onRowRemoved = (e) => {
		// Customized getDeleteMsg in store in order to get msg from server
		let response = null;
		let localResultMsg = "";
		response = dataGrid.current.instance.getDataSource().store().getDeleteMsg();
		if (response && Object.keys(response).length !== 0) {
			let message = response.data.message;
			if (response.statusText == "OK") {
				finalMsg !== "" && finalMsg.indexOf(message) !== -1 && message !== undefined
					? (localResultMsg += "")
					: (localResultMsg = message);
			} else {
				finalMsg !== "" && finalMsg.indexOf(message) !== -1 && message !== undefined
					? (localResultMsg += "")
					: (localResultMsg = message);
			}
		}
		finalMsg += localResultMsg;
	};
	const onSaving = (e) => {
		finalMsg = "";
		e.promise = confirm("Are you sure you want to save?").then((response) => {
			e.cancel = !response;
		});
	};
	const onSaved = () => {
		finalMsg = finalMsg.replace(/,/g, "<br/>");
		if (finalMsg !== "") {
			alert(finalMsg);
		}
		dataGrid.current.instance.getDataSource().store().resetResponseMsg();
		setMode("View");
	};
	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}

	return (
		<React.Fragment>
			<div>
				<div className='header-left-side'>
					<div className='header'>
						<h2>Account Calendar</h2>
					</div>
				</div>

				<form className={"filter-form"}>
					<div className='dx-fieldset'>
						<br />
						<div className='dx-labels'>Year</div>
						<div className='dx-field'>
							<SelectBox
								elementAttr={{
									id: "year"
								}}
								items={yearList}
								valueExpr='value'
								displayExpr='value'
								searchEnabled={true}
								ref={yearSelect}
								disabled={disableYear}
								onValueChanged={onValueChanged}
							/>
						</div>
						<div className='dx-field'>
							<Button
								icon='find'
								elementAttr={{
									id: "ANSfindButton"
								}}
								disabled={disableSearchBtn}
								onClick={sendReportRequest}
							/>
							<Tooltip target='#ANSfindButton' position='bottom'>
								<div>* Click on Search Icon to get Results </div>
							</Tooltip>
						</div>

						<div className='dx-field'>
							<Button icon='clear' elementAttr={{id: "ANScancelButton"}} onClick={clearFilters} />
						</div>
					</div>
				</form>
				<span>&nbsp;</span>
				<span>&nbsp;</span>
				<DataGrid
					elementAttr={{
						id: "InventoryGridContainer"
					}}
					ref={dataGrid}
					dataSource={customDataSource}
					showBorders={true}
					allowColumnResizing={true}
					columnResizingMode='nextColumn'
					columnMinWidth={100}
					columnAutoWidth={true}
					onEditorPreparing={onEditorPreparing}
					onRowPrepared={onRowPrepared}
					onOptionChanged={onOptionChanged}
					onRowUpdating={onRowUpdating}
					onRowUpdated={onRowUpdated}
					onEditCanceling={onEditCanceling}
					onEditCanceled={onEditCanceled}
					onExporting={onExporting}
					onContentReady={getTotalCount}
					onRowInserting={onRowInserting}
					onRowInserted={onRowInserted}
					onRowRemoving={onRowRemoving}
					onRowRemoved={onRowRemoved}
					onSaving={onSaving}
					onSaved={onSaved}>
					<Editing
						mode='batch'
						startEditAction={dataGridSettings.startEditAction}
						allowUpdating={permission ? permission.update : false}
						allowAdding={permission ? permission.create :false}
						allowDeleting={!disableDeleteAction}
						texts={{saveAllChanges: "", cancelAllChanges: "", addRow: ""}}
						refreshMode='full'
					/>
					<ColumnFixing enabled={true} />
					<Toolbar>
						<Item location='after'>
							<div className={"total-label"}>
								<span>Total Records: </span>
								<label>{totalCount}</label>
							</div>
						</Item>
						<Item location='after'>
							<div className={"total-label"}>
								<span> Mode: </span>
								<label id='modeLabel'>{dataGridSettings.mode}</label>
							</div>
						</Item>
						<Item>
							<Button
								icon={IconFilter}
								className='dx-toolbar-item'
								hint='Clean Filters'
								onClick={clearFiltersDG}
							/>
						</Item>
						<Item name='addRowButton' />
						<Item name='revertButton' />
						<Item name='saveButton' />
						<Item name='exportButton' />
						<Item name='searchPanel' />
					</Toolbar>
					<SearchPanel visible={true} width={240} placeholder='Search...' />
					<Selection mode='single' />
					<RemoteOperations groupPaging={true} />
					<Export enabled={true} />
					<FilterRow visible={true} applyFilter={{key: "auto", name: "Immediately"}} />
					<Column dataField='id' caption='No.' alignment='center' visible={false} />
					<Column
						dataField='yearMonth'
						alignment='center'
						caption='Year Month'
						cssClass='cell-bold'
						calculateDisplayValue='yearMonth'>
						<Lookup dataSource={yearMonths} valueExpr='value' displayExpr='value' />
						<RequiredRule />
					</Column>
					<Column
						dataField='startDate'
						allowFiltering={false}
						alignment='center'
						caption='Start Date'
						dataType='date'
						format={formatDate}>
						<RequiredRule />
					</Column>
					<Column
						dataField='endDate'
						allowFiltering={false}
						alignment='center'
						caption='End Date'
						dataType='date'
						format={formatDate}>
						<RequiredRule />
					</Column>
					<Paging defaultPageSize={10} defaultPageIndex={0} />
					<Pager
						visible={true}
						allowedPageSizes={allowedPageSizes}
						displayMode='full'
						showPageSizeSelector={dataGridSettings.showPageSizeSelector}
						showInfo={dataGridSettings.showInfo}
						showNavigationButtons={dataGridSettings.showNavButtons}
					/>
					<Scrolling mode={dataGridSettings.infiniteScroll} rowRenderingMode='virtual' />
				</DataGrid>
			</div>
		</React.Fragment>
	);
};
