import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import { Redirect } from 'react-router-dom';
import appInfo from '../../app-info';

import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  EmailRule
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import { resetPassword } from '../../api/auth';
import './reset-password-form.scss';

const notificationText = 'We\'ve sent a temporary password to reset your password. Check your inbox.';

export default function (props) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const formData = useRef({});
  const [serverNames, setServerNames] = useState(null);
  const serverDropDownOptions = {
    items: serverNames,
    searchEnabled: true,
    displayExpr: 'name',
    displayValue: 'value',

  }

  useEffect(() => {
    let getServerNames = `${appInfo.authURL}authentication/servernames`;
    (async () => {
      try {
        await fetch(getServerNames,
          {
            method: 'get',
          })
          .then(response => response.json())
          .then(result => {
            setServerNames(result);
          }).catch(error => {
            console.warn(error);
          });
      } catch (error) {
        console.error('Error getting server names');
      }
    })();
  }, []);

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    const { email, servername } = formData.current;

    sessionStorage.removeItem('username');
    sessionStorage.setItem('username', email);
    sessionStorage.removeItem('servername');
    sessionStorage.setItem('servername', servername.value);


    setLoading(true);

    const result = await resetPassword(email, servername.value);

    setLoading(false);

    if (result.isOk) {
      history.push({
        pathname: '/change-password/1',
        state: {detail: email, servername: servername.value, message: 'Received Code'}
      })
      notify(notificationText, 'success', 2500);

    } else {
      notify(result.message, 'error', 2000);
    }
  }, [history]);

  return (
    <form className={'reset-password-form'} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={'email'}
          editorType={'dxTextBox'}
          editorOptions={emailEditorOptions}
        >
          <RequiredRule message="Email/Username is required" />
          <Label visible={false} />
        </Item>
        <Item dataField={'servername'}
          editorType="dxSelectBox"
          editorOptions={serverDropDownOptions}
        >
          <RequiredRule message="Server name is required" />
        </Item>
        <ButtonItem>
          <ButtonOptions
            elementAttr={submitButtonAttributes}
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                  : 'Reset my password'
              }
            </span>
          </ButtonOptions>

        </ButtonItem>
        <Item>
          <div className={'login-link'}>
            Return to <Link to={'/login'}>Sign In</Link>
          </div>
        </Item>
      </Form>

    </form>
  );
}

const emailEditorOptions = { stylingMode: 'filled', placeholder: 'Email/ Username' };
const submitButtonAttributes = { class: 'submit-button' };
