import React, {useState, useEffect, useRef} from "react";
import store from "../../components/hooks/store";
import DataGrid, {
	Column,
	Paging,
	FilterRow,
	Scrolling,
	Selection
} from "devextreme-react/data-grid";
import SelectBox from "devextreme-react/select-box";
import {Button} from "devextreme-react/button";
import {alert, confirm} from "devextreme/ui/dialog";
import axios from "axios";

import appInfo from "../../app-info";

import {Tooltip} from "devextreme-react/tooltip";
import BoxRelabelingGrid from "./BoxRelabelingGrid";
import DropDownBox from "devextreme-react/drop-down-box";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import {TextBox} from "devextreme-react";

export default () => {
	const [disableSearchBtn, setdisableSearchBtn] = useState(false);
	const [customDataSource, setCustomDataSource] = useState(null);
	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let token = currentUser.data.token;

	//   // /* Instance components in order to reset them when necessary */
	const plantSelectBox = useRef(null);
	const optionHarnessSelectBox = useRef(null);

	const [activePlant, setActivePlant] = useState(null); // this one will be used in case user remove the filters;
	
	const boxLabelOld = useRef(null);
	const boxLabelNew = useRef(null);

	const [params, setParams] = useState([]);
	const [plants,setPlants]= useState();
	const [plantSelected, setPlantSelected]= useState(activePlant);
	const [disableFilters, setDisableFilters] = useState(false);
	const optionHarness = ["WITH HARNESS", "WITHOUT HARNESS"];

	const [firstTimeLoading, setfirstTimeLoading] = useState(true);
	const [disableBoxLabelNew, setDisableBoxLabelNew] = useState(true);
	const [DGContainsHno, setDGContainsHno] = useState(false);
	const [showSummaryColumns, setShowSummaryColumns] = useState(true);
	const [showDGColumns, setShowDGColumns] = useState(false);
	const [reportDetail, setReportDetail] = useState({
		plantId: "",
		boxLabelOld: "",
		boxLabelNew: "",
		harnessOption: ""
	});
	const reportParamsArr = ["plantId", "boxLabelOld", "boxLabelNew", "harnessOption"];
	const [showTootlTip, setShowTooltip] = useState(true);
	const [quantity, setQuantity] = useState();

	useEffect(() => {
		let getPlants= `${appInfo.reworkURL}BoxRelabeling/Plants`;
		let getActivePlant = `${appInfo.reworkURL}BoxRelabeling/ActivePlant`;
		(async () => {
			fetch(getPlants, {
				headers: {Authorization: `Bearer ${token}`}
			})
				.then((result) => result.json())
				.then((result) => {
					let customPlant = result.map((plant) => {
						return {
							id: plant.Id,
							value: plant.Id + " - " + plant.Value
						};
					});
					setPlants(customPlant);
				})
				.catch((error) => {
					console.error("Error:", error);
				});

				await fetch(getActivePlant, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`}
				})
					.then((result) => result.json())
					.then((result) => {
						
						setReportDetail((oldValues) => {
							return {...oldValues, plantId: result[0].Id};
						});
						setParams((oldParams) => {
							return {...oldParams, plantId: result[0].Id};
						});
						
						setActivePlant(result[0].Id);
						setPlantSelected(result[0].Id);
						setParams((oldParams) => {
							return {...oldParams, optionHarness: optionHarness[0]};
						});
					})
					.catch ((error) => {
				console.error("BoxRelabeling fail getting active plant");
			});
			
		})();
	}, []);
	

	const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";
	const sendReportRequest = async () => {
		let params = "?";
		if (!quantity) {
			alert("The value in Quantity in Box is not valid.");
			return;
		}
		if (reportDetail.boxLabelNew == "") {
			alert("[20126] Invalid Box Serial No.");
			return;
		}
		reportParamsArr.forEach(function (i) {
			if (i in reportDetail && isNotEmpty(reportDetail[i])) {
				params += `${i}=${JSON.stringify(reportDetail[i])}&`;
			}
		});
		params = params.replace(/"/g, "");
		params = params.slice(0, -1);

		try {
			await fetch(`${appInfo.reworkURL}BoxRelabeling/${params}`, {
				method: "get",
				headers: {Authorization: `Bearer ${token}`}
			})
				.then((result) => result.json())
				.then((result) => {
					setDisableFilters(true);
					setCustomDataSource(
						store({
							url: `${appInfo.reworkURL}BoxRelabeling${params}&quantity=${quantity}`
						})
					);
				});
		} catch (error) {
			console.error(error, "BoxRelabeling fail getting Quantity");
		}
		setShowTooltip(false);
		setfirstTimeLoading(false);
	};
	const stateUp = (value) => {
		setDGContainsHno(value);
	};
	const onValueChangeDropDown =(e)=>{
		setPlantSelected(e.value);
		setReportDetail((oldValues) => {
			return {...oldValues, plantId: e.value};
		});
	}
	const deleteAll = async () => {
		const dialogResult = await confirm(
			"[20129] Do you want to modify option with harness/without harness?"
		);
		if (dialogResult) {
			return await axios
				.delete(`${appInfo.reworkURL}BoxRelabeling/`, {
					headers: {Authorization: `Bearer ${token}`},
					data: {
						PlantId: params.plantId,
						boxLabelNew: params.boxLabelNew,
						boxLabelOld: params.boxLabelOld,
						HarnessOption: params.optionHarness
					}
				})
				.then((res) => {
					if (res.data.message) {
						alert(res.data.message);
						setCustomDataSource(null);
					}
				});
		}
	};
	const setReportParams = async (e) => {
		let fieldName = e.element.id;
		let currentFieldVal = e.value;

		switch (fieldName) {
			case "boxLabelOld":
				if (currentFieldVal !== "" && currentFieldVal !== null) {
					let params = `?plantId=${reportDetail.plantId}&boxLabelOld=${currentFieldVal}&harnessOption=${reportDetail.harnessOption}`;
					// **** INFO: validate OldBoxValue exist **** //
					try {
						await fetch(`${appInfo.reworkURL}BoxRelabeling/Quantity${params}`, {
							method: "get",
							headers: {Authorization: `Bearer ${token}`}
						})
							.then((result) => result.json())
							.then((result) => {
								if (typeof result === "number") {
									setQuantity(result.toString());
									setDisableBoxLabelNew(false);
									setParams((reportDetail) => {
										return {...reportDetail, quantity: result.toString()};
									});
									setParams((oldParams) => {
										return {...oldParams, boxLabelOld: currentFieldVal};
									});
								} else {
									alert(result.message);
								}
							});
					} catch (error) {
						// console.warn();
					}
					setReportDetail((oldValues) => {
						return {...oldValues, boxLabelOld: currentFieldVal};
					});
				} else {
					setQuantity("");
					setDisableBoxLabelNew(true);
					setReportDetail((oldValues) => {
						return {...oldValues, boxLabelOld: currentFieldVal};
					});
				}
				break;
			case "boxLabelNew":
				if (currentFieldVal !== "" && currentFieldVal !== null) {
					// **** INFO: validate NewBoxValue is valid **** //
					let params = `?plantId=${reportDetail.plantId}&boxLabelOld=${reportDetail.boxLabelOld}&boxLabelNew=${currentFieldVal}&harnessOption=${reportDetail.harnessOption}`;
					setParams((oldParams) => {
						return {...oldParams, boxLabelNew: currentFieldVal};
					});
					try {
						await fetch(`${appInfo.reworkURL}BoxRelabeling/Quantity${params}`, {
							method: "get",
							headers: {Authorization: `Bearer ${token}`}
						})
							.then((result) => result.json())
							.then((result) => {
								if (typeof result !== "number") {
									alert(result.message);
								}
							});
					} catch (error) {
						console.warn(error);
					}
					setReportDetail((oldValues) => {
						return {...oldValues, boxLabelNew: currentFieldVal};
					});
				} else {
					setReportDetail((oldValues) => {
						return {...oldValues, boxLabelNew: currentFieldVal};
					});
				}
				break;
			case "harnessOption":
				if (currentFieldVal === "WITHOUT HARNESS" && DGContainsHno === true) {
					await deleteAll();
				}
				if (currentFieldVal !== "" && currentFieldVal !== null) {
					setReportDetail((oldValues) => {
						setParams((oldParams) => {
							return {...oldParams, optionHarness: currentFieldVal};
						});
						return {...oldValues, harnessOption: encodeURIComponent(currentFieldVal)};
					});
				} else {
					setReportDetail((oldValues) => {
						setParams((oldParams) => {
							return {...oldParams, optionHarness: ""};
						});
						return {...oldValues, harnessOption: encodeURIComponent(currentFieldVal)};
					});
				}
				break;
		}
	};

	const clearFilters = () => {
		sessionStorage.setItem("totalCount", 0);
		setCustomDataSource(null);
		boxLabelOld.current.instance.reset();
		boxLabelNew.current.instance.reset();
		setDisableFilters(false);
		setPlantSelected(activePlant);
	};

	return (
		<React.Fragment>
			<div>
				<div className='header-left-side'>
					<div className='header'>
						<h2>Box Relabeling</h2>
					</div>
				</div>
				<form id='PrintingDetails' className={"printing-details"}>
					<div className='dx-fieldset'>
						<div className='dx-field'>
							<div className='dx-field-value'>
							<SelectBox
								elementAttr={{
								id: "mfgPlant"
								}}
								ref={plantSelectBox}
								valueExpr='id'
								displayExpr='value'
								searchEnabled={true}
								disabled={disableFilters}
								items={plants}
								showClearButton={true}
								value={plantSelected}
								onValueChanged={onValueChangeDropDown}
								/>
							</div>
						</div>
						<div className='dx-field'>
							<div className='dx-field-value'>
								<TextBox
									placeholder='Box Label (Old)'
									ref={boxLabelOld}
									readOnly={disableFilters}
									elementAttr={{
										id: "boxLabelOld"
									}}
									onValueChanged={setReportParams}
									showClearButton={true}
									maxLength={15}
								/>
							</div>
						</div>
						<div className='dx-field'>
							<div className='dx-field-value'>
								<TextBox
									placeholder='Box Label (New)'
									ref={boxLabelNew}
									elementAttr={{
										id: "boxLabelNew"
									}}
									onValueChanged={setReportParams}
									showClearButton={true}
									maxLength={15}
									disabled={disableBoxLabelNew}
									readOnly={disableFilters}
								/>
							</div>
						</div>
						<div className='dx-field'>
							<div className='dx-field-value'>
								<SelectBox
									placeholder='Option'
									elementAttr={{
										id: "harnessOption"
									}}
									items={optionHarness}
									defaultValue={optionHarness[0]}
									ref={optionHarnessSelectBox}
									searchEnabled={true}
									onValueChanged={setReportParams}
								/>
							</div>
						</div>
						<div className='dx-field'>
							<div className='dx-field-value'>
								<TextBox
									placeholder='Quantity in Box'
									elementAttr={{
										id: "quantityInBox"
									}}
									onValueChanged={setReportParams}
									showClearButton={true}
									readOnly={true}
									value={quantity}
								/>
							</div>
						</div>
						<div className='dx-field'>
							<div className='dx-field-value'>
								<Button
									icon='find'
									elementAttr={{
										id: "ANSfindButton"
									}}
									disabled={disableSearchBtn}
									onClick={sendReportRequest}
								/>
								<Tooltip target='#ANSfindButton' position='bottom'>
									<div>* Click on Search Icon to get Results </div>
								</Tooltip>
							</div>
						</div>
						<div className='dx-field'>
							<div className='dx-field-value'>
								<Button icon='clear' elementAttr={{id: "ANScancelButton"}} onClick={clearFilters} />
							</div>
						</div>
					</div>
				</form>
				<BoxRelabelingGrid
					showSummaryColumns={showSummaryColumns}
					showDGColumns={showDGColumns}
					customDataSource={customDataSource}
					firstTimeLoading={firstTimeLoading}
					params={params}
					stateUp={stateUp}
				/>
			</div>
		</React.Fragment>
	);
};
