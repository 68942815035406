import React, {useState, useRef, useEffect, useCallback} from "react";
import store from "../../components/hooks/store";
import Button from "devextreme-react/button";
import {CheckBox} from "devextreme-react/check-box";
import DataGrid, {
	Column,
	Editing,
	Paging,
	FilterRow,
	Toolbar,
	Item,
	ColumnFixing,
	SearchPanel,
	Pager,
	Export,
	Scrolling,
	Sorting,
	RequiredRule,
	Lookup
} from "devextreme-react/data-grid";
import {
    getPermission,
	setMode,
	getMode,
} from "../../pages/configuration/helpers/globalFunctions";
import {confirm, alert} from "devextreme/ui/dialog";
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import IconFilter from "../../components/filter.svg";
import saveAs from "file-saver";import appInfo from "../../app-info";
export default ({customDataSource, shipToWhseList, adhereceType}) =>{
    const allowedPageSizes = [10, 30, 50, "all"];

    let finalMsg = "";
	let canceled = false;
	let currentAdhere = false;
    let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let token = currentUser.data.token;
	let language = currentUser.data.userData.language;
    const permission = getPermission(currentUser, "ShippingPlan", "ShipmentPlanCheckMaster");

	const [updInvalidAhereList, setUpdInvalidAhereList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
	const [insertInvalidAhereList, setInsertInvalidAhereList] = useState([]);
	const dataGrid = useRef(null);
	const [dataGridSettings, setdataGridSettings] = useState({
		selectTextOnEditStart: true,
		startEditAction: "click",
		showHeaderFilter: true,
		showPageSizeSelector: true,
		showFilterRow: true,
		showInfo: true,
		showNavButtons: true,
		infiniteScroll: "standard"
	});
    const getTotalCount = () => {
		let currentTotal = sessionStorage.getItem("totalCount");
		setTotalCount(currentTotal);
	};
	const onEditorPreparing = (e) => {
		if (e.dataField === "shipToWhse" && e.parentType === "dataRow") {
			e.editorOptions.disabled = !e.row.isNewRow;
		}
		if (e.dataField === "adhere" && e.parentType === "dataRow") {
			currentAdhere = e.value;
		}
		if (e.dataField === "adherecetype" && e.parentType === "dataRow") {
			e.editorOptions.value = "";
			e.editorOptions.disabled = !currentAdhere;
		}
	};
	const onEditCanceled = useCallback((e) => {
		setMode("View");
	}, []);

	const onEditCanceling = useCallback((e) => {
		if (canceled) {
			return;
		} else {
			e.cancel = true;
			confirm("Unsaved data exist. <br/> Are you sure you want to cancel?").then((confirm) => {
				if (confirm) {
					canceled = true;
					e.component.cancelEditData();
					canceled = false;
				}
			});
		}
	}, []);

	const onRowInserting = (e) => {
		if (!e.data.adhere) {
			e.data.adhere = false;
		}
		if (e.data.adhere && !e.data.adherecetype) {
			setInsertInvalidAhereList((prevValues) => [...prevValues, e.data.shipToWhse]);
			e.data = [];
		} else {
			e.data = [e.data];
		}
	};
	const onRowInserted = (e) => {
		let localResultMsg = "";
		let temporaryArray = insertInvalidAhereList;
		if (e.data.data.message === "") {
			localResultMsg += `Adherence Type needs to be selected: ${insertInvalidAhereList[0]},`;
			temporaryArray.shift();
			setInsertInvalidAhereList(temporaryArray);
		} else {
			let message = e.data.data.message;
			if (e.data.statusText == "OK") {
				//e.data.status == 200
				finalMsg !== "" && !finalMsg.indexOf(message) && message !== undefined
					? (localResultMsg += "")
					: (localResultMsg = message);
			} else {
				finalMsg !== "" && !finalMsg.indexOf(message) && message !== undefined
					? (localResultMsg += "" + message)
					: (localResultMsg = message);
			}
		}
		finalMsg += localResultMsg;
	};
	const onRowUpdating = (e) => {
		if (e.newData.adhere === false) {
			e.newData.adherecetype = "";
		}

		if (e.newData.adhere && !e.newData.adherecetype) {
			setUpdInvalidAhereList((prevValues) => [...prevValues, e.oldData.shipToWhse]);
			e.newData = [];
		} else {
			e.newData = [{...e.oldData, ...e.newData}];
		}
	};
	const onRowUpdated = (e) => {
		let localResultMsg = "";
		let tempArray = updInvalidAhereList;
		if (e.data.data.message === "") {
			localResultMsg += `Adherence Type needs to be selected: ${updInvalidAhereList[0]},`;
			tempArray.shift();
			setUpdInvalidAhereList(tempArray);
		} else {
			let message = e.data.data.message;
			if (e.data.statusText == "OK") {
				//e.data.status == 200
				finalMsg !== "" && !finalMsg.indexOf(message)
					? (localResultMsg += "")
					: (localResultMsg = message);
			} else {
				finalMsg !== "" && !finalMsg.indexOf(message)
					? (localResultMsg += "")
					: (localResultMsg = message);
			}
		}
		finalMsg += localResultMsg;
	};
	const onRowRemoving = (e) => {
		var changes = e.component.option("editing.changes");
		changes.find((c) => c.key === e.key).key = [e.data];
		e.component.option("editing.changes", changes);
	};
	const onRowRemoved = (e) => {
		// Customized getDeleteMsg in store in order to get msg from server
		let response = null;
		let localResultMsg = "";
		response = dataGrid.current.instance.getDataSource().store().getDeleteMsg();
		if (response && Object.keys(response).length !== 0) {
			let message = response.data.message;
			if (response.statusText == "OK") {
				finalMsg !== "" && finalMsg.indexOf(message) !== -1 && message !== undefined
					? (localResultMsg += "")
					: (localResultMsg = message);
			} else {
				finalMsg !== "" && finalMsg.indexOf(message) !== -1 && message !== undefined
					? (localResultMsg += "")
					: (localResultMsg = message);
			}
			finalMsg += localResultMsg;
		}
	};
	const onSaving = (e) => {
		finalMsg = "";
		e.promise = confirm("Are you sure you want to save?").then((response) => {
			e.cancel = !response;
		});
	};
	const onSaved = () => {
		finalMsg = finalMsg.replace(/,/g, "<br/>");
		if (finalMsg !== "") {
			alert(finalMsg);
		}
		dataGrid.current.instance.getDataSource().store().resetResponseMsg();
		setMode("View");
	};
	const clearFilters = () => {
		dataGrid.current.instance.clearFilter();
	};
    const onOptionChangedGrid = useCallback((e) => {
		if (e.fullName !== "editing.changes") {
			return;
		}

		if (getMode() === "View") {
			setMode("Edit");
		}
	}, []);
	const onExporting = (e) => {
		/* Get today Date First */
		let today = new Date();
		let month = today.getMonth() + 1; //months from 1-12
		let day = today.getDate();
		let year = today.getUTCFullYear();
		let buildTitle;
		language === "ES"
			? (buildTitle = `Shipment Plan Check Master ${day}-${month}-${year}.xlsx`)
			: (buildTitle = `Shipment Plan Check Master ${month}-${day}-${year}.xlsx`);
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet(buildTitle);

		exportDataGrid({
			component: e.component,
			worksheet: worksheet
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], {type: "application/octet-stream"}), buildTitle);
			});
		});
		e.cancel = true;
	};
return (
    <React.Fragment>
<DataGrid
					elementAttr={{
						id: "shipmentPlanCheckDataGrid"
					}}
					dataSource={customDataSource}
					ref={dataGrid}
					allowColumnResizing={true}
					columnResizingMode='nextColumn'
					columnAutoWidth={true}
					columnMinWidth={100}
					showBorders={true}
					remoteOperations={true}
					onExporting={onExporting}
					onContentReady={getTotalCount}
					onEditorPreparing={onEditorPreparing}
					onEditCanceled={onEditCanceled}
					onEditCanceling={onEditCanceling}
					onRowInserting={onRowInserting}
					onRowInserted={onRowInserted}
					onRowUpdating={onRowUpdating}
					onRowUpdated={onRowUpdated}
					onRowRemoving={onRowRemoving}
					onRowRemoved={onRowRemoved}
					onOptionChanged={onOptionChangedGrid}
					onSaving={onSaving}
					onSaved={onSaved}>
					<Toolbar>
						<Item location='after'>
							<div className={"total-label"}>
								<span>Total Records: </span>
								<label>{totalCount}</label>
							</div>
						</Item>
						<Item location='after'>
							<div className={"total-label"}>
								<span> Mode: </span>
								<label id='modeLabel'>View</label>
							</div>
						</Item>
						<Item>
							<Button
								icon={IconFilter}
								className='dx-toolbar-item'
								hint='Clean Filters'
								onClick={clearFilters}
							/>
						</Item>
						<Item name='exportButton' />
						<Item name='addRowButton' />
						<Item name='revertButton' />
						<Item name='saveButton' />
						<Item name='searchPanel' />
					</Toolbar>
					<Sorting mode='multiple' />
					<FilterRow visible={true} applyFilter={{key: "auto", name: "Immediately"}} />
					<SearchPanel visible={true} width={240} placeholder='Search...' />
					<Export enabled={true} />
					<ColumnFixing enabled={true} />
					<Editing
						mode='batch'
						startEditAction={dataGridSettings.startEditAction}
						allowAdding={permission.create}
						allowUpdating={permission.update}
						allowDeleting={permission.delete}
						texts={{saveAllChanges: "", cancelAllChanges: "", addRow: ""}}
						refreshMode='full'
					/>
					<Column dataField='id' caption='ID' allowEditing={false} visible={false} />
					<Column dataField='shipToWhse' caption='Ship To WH SE' cssClass='cell-bold'>
						<RequiredRule />
						<Lookup dataSource={shipToWhseList} valueExpr='value' displayExpr='value' />
					</Column>
					<Column dataField='adhere' dataType='boolean' caption='Adhere'>
						<CheckBox></CheckBox>
					</Column>
					<Column dataField='adherecetype' caption='Adherence Type'>
						<Lookup dataSource={adhereceType} displayExpr='Value' valueExpr='Value' />
					</Column>
					<Paging defaultPageSize={10} defaultPageIndex={0} />
					<ColumnFixing enabled={true} />
					<Pager
						visible={true}
						allowedPageSizes={allowedPageSizes}
						displayMode='full'
						showPageSizeSelector={dataGridSettings.showPageSizeSelector}
						showInfo={dataGridSettings.showInfo}
						showNavigationButtons={dataGridSettings.showNavButtons}
					/>
					<Scrolling mode={dataGridSettings.infiniteScroll} rowRenderingMode='virtual'/>
				</DataGrid>
                </React.Fragment>
	);
}