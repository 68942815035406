import React, {useRef, useState, useEffect, useCallback} from "react";
import DataGrid, {
	Column,
	Editing,
	Paging,
	FilterRow,
	SearchPanel,
	Toolbar,
	ColumnFixing,
	Item,
	Pager,
	Export,
	Scrolling,
	Sorting,
	Lookup,
	RequiredRule,
} from "devextreme-react/data-grid";
import {getPermission, setMode, getMode} from "./helpers/globalFunctions";
import {Workbook} from "exceljs";
import Button from "devextreme-react/button";
import {Redirect} from "react-router-dom";
import {exportDataGrid} from "devextreme/excel_exporter";
import {alert, confirm} from "devextreme/ui/dialog";
import store from "../../components/hooks/store";
import saveAs from "file-saver";
import appInfo from "../../app-info";
import axios from "axios";
import IconFilter from "../../components/filter.svg";

const params = {
	url: `${appInfo.configURL}WarehouseAddressMaster`,
};
export default () => {
	const [totalCount, setTotalCount] = useState(0);
	const dataGrid = useRef(null);
	const allowedPageSizes = [10, 30, 50, "all"];
	let finalMsg = "";
	let canceled;
	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let token = currentUser ? currentUser.data.token : '';
	let language = currentUser ? currentUser.data.userData.language : '';
	const permission = getPermission(
		currentUser,
		"ConfigurationMasters",
		"WarehouseAddressMaster"
	);
	const CustomDataSource = store(params);

	const [warehouseList, setWarehouseList] = useState(null);
	const [dataGridSettings, setdataGridSettings] = useState({
		selectTextOnEditStart: true,
		startEditAction: "click",
		showHeaderFilter: true,
		showPageSizeSelector: true,
		showFilterRow: true,
		showInfo: true,
		showNavButtons: true,
		viewmode: true,
		infiniteScroll: "standard",
	});

	useEffect(() => {
		(async () => {
			let getWarehouse = `${appInfo.configURL}WarehouseAddressMaster/Warehouses`;
			try {
				await axios
					.get(getWarehouse, {headers: {Authorization: `Bearer ${token}`}})
					.then((result) => {
						setWarehouseList(result.data);
					})
					.catch((error) => {
						console.warn(error);
					});
			} catch (error) {
				console.error("Destination fail get default warehouse");
			}
		})();
	}, []);
	/*  Init configuration  */
	const getTotalCount = () => {
		let total = sessionStorage.getItem("totalCount");
		setTotalCount(total);
	};

	const onEditCanceling = async (e) => {
		if (canceled) {
			return;
		} else {
			e.cancel = true;
			const dialogResult = await confirm(
				"Unsaved data exist. <br/> Are you sure you want to cancel?"
			);
			if (dialogResult) {
				canceled = true;
				e.component.cancelEditData();
				canceled = false;
			}
		}
	};
	const onEditCanceled = (e) => {
		setMode("View");
	};
	const clearFilters = () => {
		dataGrid.current.instance.clearFilter();
	};
	const onExporting = (e) => {
		/* Get today Date First */
		let today = new Date();
		let month = today.getMonth() + 1; //months from 1-12
		let day = today.getDate();
		let year = today.getUTCFullYear();
		let buildTitle;
		language === "ES"
			? (buildTitle = `Warehouse Address Destination Master ${day}-${month}-${year}.xlsx`)
			: (buildTitle = `Warehouse Address Destination Master ${month}-${day}-${year}.xlsx`);
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet(buildTitle);

		exportDataGrid({
			component: e.component,
			worksheet: worksheet,
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(
					new Blob([buffer], {type: "application/octet-stream"}),
					buildTitle
				);
			});
		});
		e.cancel = true;
	};

	/* Functionality */
	const onRowUpdating = (e) => {
		e.newData = [{...e.oldData, ...e.newData}];
	};
	const onRowUpdated = (e) => {
		let localResultMsg = "";
		let message = e.data.data.message;
		if (e.data.statusText == "OK") {
			//e.data.status == 200
			finalMsg !== "" && !finalMsg.indexOf(message)
				? (localResultMsg += "")
				: (localResultMsg = message);
		} else {
			finalMsg !== "" && !finalMsg.indexOf(message)
				? (localResultMsg += "")
				: (localResultMsg = message);
		}
		finalMsg += localResultMsg;
	};
	const onRowInserting = (e) => {
		e.data = [e.data];
	};
	const onRowInserted = (e) => {
		let localResultMsg = "";
		let message = e.data.data.message;

		if (e.data.statusText == "OK") {
			//e.data.status == 200
			finalMsg !== "" && !finalMsg.indexOf(message) && message !== undefined
				? (localResultMsg += "")
				: (localResultMsg = message);
		} else {
			finalMsg !== "" && !finalMsg.indexOf(message) && message !== undefined
				? (localResultMsg += "" + message)
				: (localResultMsg = message);
		}
		finalMsg += localResultMsg;
	};
	const onRowRemoving = (e) => {
		var changes = e.component.option("editing.changes");
		changes.find((c) => c.key === e.key).key = [e.data];
		e.component.option("editing.changes", changes);
	};
	const onRowRemoved = async (e) => {
		// Customized getDeleteMsg in store in order to get msg from server
		let response = null;
		let localResultMsg = "";
		response = dataGrid.current.instance.getDataSource().store().getDeleteMsg();
		if (response && Object.keys(response).length !== 0) {
			let message = response.data.message;
			if (response.statusText == "OK") {
				finalMsg !== "" &&
				finalMsg.indexOf(message) !== -1 &&
				message !== undefined
					? (localResultMsg += "")
					: (localResultMsg = message);
			} else {
				finalMsg !== "" &&
				finalMsg.indexOf(message) !== -1 &&
				message !== undefined
					? (localResultMsg += "")
					: (localResultMsg = message);
			}
		}
		finalMsg += localResultMsg;
	};
	const onSaving = (e) => {
		finalMsg = "";
		e.promise = confirm("Are you sure you want to save?").then((response) => {
			e.cancel = !response;
		});
	};
	const onSaved = (e) => {
		finalMsg = finalMsg.replace(/,/g, "<br/>");
		if (finalMsg !== "") {
			alert(finalMsg);
		}
		dataGrid.current.instance.getDataSource().store().resetResponseMsg();
		setMode("View");
	};

	const onOptionChanged = useCallback((e) => {
		if (e.fullName !== "editing.changes") {
			return;
		}

		if (getMode() === "View") {
			setMode("Edit");
		}
	}, []);

	const onEditorPreparing = (e) => {
		if (e.dataField === "warehouse" && e.parentType === "dataRow") {
			e.editorOptions.disabled = !e.row.isNewRow;
		}
	};
	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}
	return (
		<React.Fragment>
			<h2 className={"content-block"}>Warehouse Address Master</h2>
			<div id="data-grid-demo" className={"content-block"}>
				<DataGrid
					elementAttr={{
						id: "WareHouseAddressMasterDataGrid",
					}}
					ref={dataGrid}
					dataSource={CustomDataSource}
					allowColumnResizing={true}
					showBorders={true}
					columnResizingMode="nextColumn"
					columnMinWidth={100}
					columnAutoWidth={true}
					remoteOperations={true}
					onContentReady={getTotalCount}
					onExporting={onExporting}
					onEditCanceling={onEditCanceling}
					onEditCanceled={onEditCanceled}
					onRowUpdating={onRowUpdating}
					onRowUpdated={onRowUpdated}
					onRowInserting={onRowInserting}
					onRowInserted={onRowInserted}
					onRowRemoving={onRowRemoving}
					onRowRemoved={onRowRemoved}
					onSaving={onSaving}
					onSaved={onSaved}
					onOptionChanged={onOptionChanged}
					onEditorPreparing={onEditorPreparing}>
					<ColumnFixing enabled={true} />
					<Toolbar>
						<Item location="after">
							<div className={"total-label"}>
								<span>Total Records: </span>
								<label>{totalCount}</label>
							</div>
						</Item>
						<Item location="after">
							<div className={"total-label"}>
								<span> Mode: </span>
								<label id="modeLabel">View</label>
							</div>
						</Item>
						<Item>
							<Button
								icon={IconFilter}
								className="dx-toolbar-item"
								hint="Clean Filters"
								onClick={clearFilters}
							/>
						</Item>
						<Item name="exportButton" />
						<Item name="addRowButton" />
						<Item name="revertButton" />
						<Item name="saveButton" />
						<Item name="searchPanel" />
					</Toolbar>
					<Sorting mode="multiple" />
					<FilterRow
						visible={true}
						applyFilter={{key: "auto", name: "Immediately"}}
					/>
					<SearchPanel visible={true} width={240} placeholder="Search..." />
					<Export enabled={true} />
					<Editing
						mode="batch"
						startEditAction={dataGridSettings.startEditAction}
						allowAdding={permission ? permission.create : false}
						allowUpdating={permission ? permission.update : false}
						allowDeleting={permission ? permission.delete : false}
						texts={{saveAllChanges: "", cancelAllChanges: "", addRow: ""}}
						refreshMode="full"
					/>
					<Column
						dataField="id"
						caption="No"
						allowEditing={false}
						cssClass="cell-bold"
						visible={false}
						allowFiltering={false}
					/>
					<Column
						dataField="warehouse"
						caption="Warehouse"
						cssClass="cell-bold"
						allowEditing={true}>
						<RequiredRule />
						<Lookup
							dataSource={warehouseList}
							displayExpr="value"
							valueExpr="value"
						/>
					</Column>
					<Column
						dataField="address"
						caption="Address"
						allowEditing={true}
						editorOptions={{maxLength: 200}}>
						<RequiredRule />
					</Column>
					<Paging defaultPageSize={10} defaultPageIndex={0} />
					<Pager
						visible={true}
						allowedPageSizes={allowedPageSizes}
						displayMode="full"
						showPageSizeSelector={dataGridSettings.showPageSizeSelector}
						showInfo={dataGridSettings.showInfo}
						showNavigationButtons={dataGridSettings.showNavButtons}
					/>
					<Scrolling mode={dataGridSettings.infiniteScroll} rowRenderingMode='virtual'/>
				</DataGrid>
			</div>
		</React.Fragment>
	);
};
