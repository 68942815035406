import React, {useEffect, useState, useRef} from "react";
import {useAuth} from "../../../contexts/auth";
import appInfo from "../../../app-info";
import ShipmentPlantItem from "./ShipmentPlanMaintenanceItem";
import SelectBox from "devextreme-react/select-box";

export default ({callbackDropdownBoxPlant, disableFilters}) => {
	const {user} = useAuth();
	const [plants, setPlants] = useState();

	const selectBox = useRef();
	useEffect(() => {
		let getPlants = `${appInfo.shippingURL}ShipmentPlanMaintenanceMaster/Plants`;

		(async () => {
			await fetch(getPlants, {
				method: "get",
				headers: {Authorization: `Bearer ${user.data.token}`}
			})
				.then((response) => response.json())
				.then((result) => {
					setPlants(result.data);
				})
				.catch((error) => {
					console.error("Fail requesting Part Mapping get plants", error);
				});
		})();
		window.addEventListener("resetFilters", () => {
			selectBox.current.instance.reset();
		});
	}, []);
	const onValueChanged = (e) => {
		callbackDropdownBoxPlant(e.value);
	};
	return (
		<SelectBox
			dataSource={plants}
			valueExpr='plantId'
			displayExpr='plantId'
			elementAttr={{
				id: "mfgPlant"
			}}
			width='300px'
			ref={selectBox}
			disabled={disableFilters}
			itemRender={ShipmentPlantItem}
			searchEnabled={true}
			searchExpr={["plantId", "value"]}
			searchMode='contains'
			onValueChanged={onValueChanged}></SelectBox>
	);
};
