import React from "react";
import {Switch, Route, Redirect} from "react-router-dom";
import appInfo from "./app-info";
import routes from "./app-routes";
import {SideNavOuterToolbar as SideNavBarLayout} from "./layouts";
import {Footer} from "./components";
import {useAuth} from "../src/contexts/auth";
import notify from "devextreme/ui/notify";

export default function Content() {
	const {user, signOut} = useAuth();
	const adminForm = sessionStorage.getItem("isAdminForm");
	let groupCode = user.data.userData.groupCode;
	return (
		<SideNavBarLayout title={appInfo.title}>
			<Switch>
				{routes.map(({path, component}) => (
					<Route exact key={path} path={path} component={component} />
				))}

				{adminForm === "false" && groupCode !== "SUPER" ? (
					<Redirect to={"/home"} />
				) : adminForm === "true" && groupCode !== "SUPER" ? (
					(notify(
						"Username has not administrator rights, you have been redirected to login page",
						"error",
						3000
					),
					signOut(),
					(<Redirect to={"/login"} />))
				) : adminForm === "true" && groupCode === "SUPER" ? (
					<Redirect to={"/serverStatus"} />
				) : adminForm === "false" && groupCode === "SUPER" ? (
					<Redirect to={"/home"} />
				) : null}
			</Switch>
			<Footer>
       Sumitomo Corporation
      </Footer>
		</SideNavBarLayout>
	);
}
