import React, {useState, useRef, useEffect} from "react";
import {Button} from "devextreme-react/button";
import DateBox from "devextreme-react/date-box";
import RadioGroup from "devextreme-react/radio-group";
import store from "../../components/hooks/store";
import appInfo from "../../app-info";
import {Redirect} from "react-router-dom";
import {getPermission} from "../configuration/helpers/globalFunctions";
import {Tooltip} from "devextreme-react/tooltip";
import ReturnProductsReportGrid from "./ReturnProductsReportGrid";
import ReturnSelectBoxCustPart from "../additionalComponents/ReturnSelectBoxCustPart";
import ReturnProductsSelectBoxPlants from "./additionalComponents/ReturnProductsSelectBoxPlants";
import ReturnSelectBoxAsn from "../additionalComponents/ReturnSelectBoxAsn";
import {useAuth} from "../../contexts/auth";

export default () => {
	const [customDataSource, setCustomDataSource] = useState(null);
	const [disableSearchBtn, setdisableSearchBtn] = useState(false);
	const {user} = useAuth();
	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

	const permission = getPermission(currentUser, "MISReports", "ReturnProductsReport");

	/* Instance components in order to reset them when necessary */
	const dateFromBox = useRef(null);
	const dateToBox = useRef(null);
	const reportRadioBtn = useRef(null);
	const partNumRadioBtn = useRef(null);
	let language = user ? user.data.userData.language : '';
	let userFormatDate = user ? user.data.userData.formatDate :'';
	const[formatDate,setFormatDate] =useState('MMM, yyyy');
	const reportOptions = ["Summary", "Detail"];
	const partNumber = ["Customer", "SEWS"];

	const [maxToDate, setMaxToDate] = useState();
	const [minToDate, setMinToDate] = useState(new Date(2000, 0, 1));
	const currentDate = new Date();
	const [reportOptionSelected, setReportOptionSelected] = useState(reportOptions[0]);
	const [partNumSelected, setPartNumberSelected] = useState(partNumber[0]);
	const [showSelectBox, setShowSelectBox] = useState(true);
	const [disableFilters, setDisableFilters] = useState(false);
	const [firstTimeLoading, setfirstTimeLoading] = useState(true);
	const [showDGColumns, setShowDGColumns] = useState(false);
	const [reportDetail, setReportDetail] = useState({
		plantID: "",
		partNumber: "",
		ASN: "",
		reportOption: "S",
		partNumberOption: "C",
		dateFrom: "",
		dateTo: ""
	});

	const reportParamsArr = [
		"plantID",
		"reportOption",
		"partNumberOption",
		"partNumber",
		"ASN",
		"dateFrom",
		"dateTo"
	];
	const [showTootlTip, setShowTooltip] = useState(true);
	const [fromDateValue, setFromDateVal] = useState();
	const [toDateVal, setToDateVal] = useState();

	const partNumOnValueChange = (e) => {
		e.promise = setPartNumberSelected(e.value);
		setShowTooltip(true);
		if (e.value === "SEWS") {
			setShowSelectBox(false);
			setReportDetail((oldValues) => {
				return {...oldValues, partNumberOption: "S"};
			});
		} else {
			setShowSelectBox(true);
			setReportDetail((oldValues) => {
				return {...oldValues, partNumberOption: "C"};
			});
		}
	};
	const reportOptionOnChange = (e) => {
		e.promise = setReportOptionSelected(e.value);
		if (e.value == "Summary") {
			setReportDetail((oldValues) => {
				return {...oldValues, reportOption: "S"};
			});
			setFormatDate('MMM, yyyy');
			setShowDGColumns(false);
		} else {
			//turn on flag to show extra columns
			setFormatDate(userFormatDate);
			setShowDGColumns(true);
			setReportDetail((oldValues) => {
				return {...oldValues, reportOption: "D"};
			});
		}
		setFromDateVal(currentDate);
		setToDateVal(currentDate);
	};
	const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";
	const sendReportRequest = () => {
		let params = "?";
		reportParamsArr.forEach(function (i) {
			if (i in reportDetail && isNotEmpty(reportDetail[i])) {
				params += `${i}=${JSON.stringify(reportDetail[i])}&`;
			}
		});
		params = params.replace(/"/g, "");
		params = params.slice(0, -1);
		setCustomDataSource(
			store({
				url: `${appInfo.reportURL}ReturnProductsReport${params}`
			})
		);
		setShowTooltip(false);
		setfirstTimeLoading(false);
		setDisableFilters(true);
		setdisableSearchBtn(true);
	};
	const callbackSelectBoxPartNum = (partNum) => {
		setReportDetail((oldValues) => {
			return {...oldValues, partNumber: partNum};
		});
	};
	const callbackDropdownBoxPlant = (plantValue) => {
		setReportDetail((oldValues) => {
			return {...oldValues, plantID: plantValue};
		});
	};
	const callbackSelectBoxAsn = (asnNumber) => {
		setReportDetail((oldValues) => {
			return {...oldValues, ASN: asnNumber};
		});
	};
	const setReportParams = (e) => {
		let fieldName = e.element.id;
		let currentFieldVal = e.value;
		switch (fieldName) {
			case "returnShipmentDateFrom":
				let fromDate, maxTime, maxDate, fromYear;
				let currentDateSelected = new Date(currentFieldVal);
				let fromDay = currentDateSelected.getDate();
				let fromMonth = currentDateSelected.getMonth();
				fromYear = new Date(currentFieldVal).getFullYear();

				if (reportOptionSelected === "Summary") {
					fromDay = 1;
				}
				currentFieldVal = new Date(fromYear, fromMonth, fromDay);
				setFromDateVal(new Date(fromYear, fromMonth, fromDay));

				if (reportOptionSelected === "Summary") {
					/* Calculate dateRange for a year */
					fromDate = new Date(currentFieldVal).getTime();
					maxTime = fromDate + 1000 * 3600 * 24 * 365;
					maxDate = new Date();
					maxDate.setTime(maxTime);
					setMaxToDate(maxDate);
					setMinToDate(currentFieldVal);
				} else {
					/* set logic for getting datebox Rule not grather than 90 days */
					fromDate = new Date(currentFieldVal).getTime();
					maxTime = fromDate + 1000 * 3600 * 24 * 90;
					maxDate = new Date();
					maxDate.setTime(maxTime);

					setMaxToDate(maxDate);
					setMinToDate(currentFieldVal);
				}
				/* Validate the following scenarios: 
					- FROM date lower than TO date
					- FROM date <= maxDate
					- Date range not grather than 90 days
				*/

				if (currentFieldVal !== "") {
					//changing correct format
					let yyy = currentFieldVal.getFullYear();
					let mm = currentFieldVal.getMonth() + 1;
					let dd = currentFieldVal.getDate();
					setReportDetail((oldValues) => {
						return {...oldValues, dateFrom: `${yyy}/${mm}/${dd}`};
					});
				} else {
					setReportDetail((oldValues) => {
						return {...oldValues, dateFrom: currentFieldVal};
					});
				}
				break;
			case "shipmentDateTo":
				/* Validate TO date is not grather than FROM */
				let toDate = new Date(currentFieldVal);
				let fromDateVal = dateFromBox.current.instance.option("value");

				let month = toDate.getMonth(); //months from 1-12
				let day = toDate.getDate();
				let year = toDate.getFullYear();

				if (reportOptionSelected === "Summary") {
					day = 1;
				}
				let toDateTime_ = new Date(toDate).getTime();
				let fromDateTime = new Date(fromDateVal).getTime();
				let differenceITime_ = toDateTime_ - fromDateTime; // consider from date can be too older date

				let daysPast_ = differenceITime_ / (1000 * 3600 * 24);

				if (reportOptionSelected === "Summary") {
					if (daysPast_ < 0 || daysPast_ > 364) {
						setdisableSearchBtn(true);
					} else {
						setdisableSearchBtn(false);
					}
				} else {
					if (daysPast_ < 0 || daysPast_ > 90) {
						setdisableSearchBtn(true);
					} else {
						setdisableSearchBtn(false);
					}
				}

				currentFieldVal = new Date(year, month, day);

				setToDateVal(new Date(year, month, day));

				/* Validate the following scenarios: 
            - TO date grather than FROM date
            - TO date >= minDate
            - Date range not grather than 90 days
        */
				if (currentFieldVal !== "") {
					//changing correct format
					let yyy = currentFieldVal.getFullYear();
					let mm = currentFieldVal.getMonth() + 1;
					let dd = currentFieldVal.getDate();
					setReportDetail((oldValues) => {
						return {...oldValues, dateTo: `${yyy}/${mm}/${dd}`};
					});
				} else {
					setReportDetail((oldValues) => {
						return {...oldValues, dateTo: currentFieldVal};
					});
				}
				break;
			case "returnShipmentASN":
				if (currentFieldVal !== "") {
					setReportDetail((oldValues) => {
						return {...oldValues, ASN: currentFieldVal};
					});
				} else {
					setReportDetail((oldValues) => {
						return {...oldValues, ASN: currentFieldVal};
					});
				}
				break;
		}
	};

	const clearFilters = () => {
		const event = new CustomEvent("resetFilters");
		window.dispatchEvent(event);
		setShowTooltip(true);
		setDisableFilters(false);
		setdisableSearchBtn(false);
		setCustomDataSource(
			store({
				url: `${appInfo.reportURL}ReturnProductsReport`
			})
		);
	};

	const onInitialized = (e) => {
		let todayDate = new Date(currentDate).getTime();
		let maxTime = todayDate + 1000 * 3600 * 24 * 365;
		let maxDate = new Date();
		maxDate.setTime(maxTime);
		setMaxToDate(maxDate);
		setMinToDate(currentDate);
		setFromDateVal(todayDate);
		setToDateVal(todayDate);
	};

	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}
	return (
		<React.Fragment>
			<div>
				<div className='header-left-side'>
					<div className='header'>
						<h2>Return Products</h2>
					</div>
				</div>
				<form className={"filter-form"}>
					<div className='dx-fieldset'>
						<br />
						<div className='dx-field'>
							<div className='dx-labels'>Report Option</div>
							<RadioGroup
								items={reportOptions}
								value={reportOptionSelected}
								layout='horizontal'
								onValueChanged={reportOptionOnChange}
								ref={reportRadioBtn}
								disabled={disableFilters}
							/>
						</div>
						<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
						<div className='dx-field'>
							<div className='dx-labels'>Part Number</div>
							<RadioGroup
								items={partNumber}
								value={partNumSelected}
								onValueChanged={partNumOnValueChange}
								layout='horizontal'
								ref={partNumRadioBtn}
								disabled={disableFilters}
							/>
						</div>
					</div>
					<div className='dx-fieldset'>
						<div className='dx-field'>
							<ReturnProductsSelectBoxPlants
								callbackDropdownBoxPlant={callbackDropdownBoxPlant}
								disableFilters={disableFilters}
							/>
						</div>
						<div className='dx-field'>
							<DateBox
								elementAttr={{
									id: "returnShipmentDateFrom"
								}}
								ref={dateFromBox}
								onValueChanged={setReportParams}
								useMaskBehavior={true}
								placeholder='Date From'
								
								width='160px'
								disabled={disableFilters}
								displayFormat={formatDate}
								value={fromDateValue}
								dateSerializationFormat='yyyy/MM/dd'
								defaultValue={currentDate}
								onInitialized={onInitialized}
							/>
						</div>
						<div className='dx-field'>
							<DateBox
								elementAttr={{
									id: "shipmentDateTo"
								}}
								ref={dateToBox}
								defaultValue={currentDate}
								onValueChanged={setReportParams}
								useMaskBehavior={true}
								placeholder='To'
								displayFormat={formatDate}
								value={toDateVal}
								dateSerializationFormat='yyyy/MM/dd'
								max={maxToDate}
								min={minToDate}
								disabled={disableFilters}
								width='160px'></DateBox>
						</div>
						<div className='dx-field'>
							<ReturnSelectBoxAsn
								disableFilters={disableFilters}
								callbackSelectBoxAsn={callbackSelectBoxAsn}
							/>
						</div>
						<ReturnSelectBoxCustPart
							showSelectBox={showSelectBox}
							callbackSelectBoxPartNum={callbackSelectBoxPartNum}
							disableFilters={disableFilters}
						/>
						<div className='dx-field'>
							<Button
								icon='find'
								elementAttr={{
									id: "findButton"
								}}
								disabled={disableSearchBtn}
								onClick={sendReportRequest}
							/>
							<Tooltip target='#findButton' visible={showTootlTip} position='bottom'>
								<div>* Click on Search Icon to get Results </div>
							</Tooltip>
						</div>
						<div className='dx-field'>
							<Button icon='clear' elementAttr={{id: "cancelButton"}} onClick={clearFilters} />
						</div>
					</div>
				</form>
				<span>&nbsp;</span>
				<span>&nbsp;</span>
				<ReturnProductsReportGrid
					showDGColumns={showDGColumns}
					customDataSource={customDataSource}
					firstTimeLoading={firstTimeLoading}
					reportDetail={reportDetail}
					disableSearchBtn={disableSearchBtn}
				/>
			</div>
		</React.Fragment>
	);
};
