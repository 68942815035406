import React, {useState, useEffect} from "react";
import store from "../../components/hooks/store";
import {getPermission} from "../configuration/helpers/globalFunctions";
import SelectBox from "devextreme-react/select-box";
import axios from "axios";
import appInfo from "../../app-info";
import {Redirect} from "react-router-dom";
import BoxLabelPrintingGrid from "./BoxLabelPrintingGrid";
import Box, {Item} from "devextreme-react/box";

export default () => {
	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let token = currentUser ? currentUser.data.token : '';

	const permission = getPermission(currentUser, "DemandPrinting", "BoxLabelPrinting");

	const [plants, setPlants] = useState(null);
	const [currentPlant, setcurrentPlant] = useState("0");
	const [customDataSource, setCustomDataSource] = useState(null);

	useEffect(() => {
		let getPlants = `${appInfo.demandURL}BoxLabelPrinting/Plants`;
		let getActivePlant = `${appInfo.demandURL}BoxLabelPrinting/ActivePlant`;
		(async () => {
				await axios.get(getPlants, {headers: {Authorization: `Bearer ${token}`}}).then((result) => {
					let customPlant = result.data.map((plant) => {
						return {
							id: plant.Id,
							value: plant.Id + " - " + plant.Value
						};
					});
					setPlants(customPlant);
				}).catch((error) => {
				console.error(error," Fail requesting get plants");
			})
			
				await axios
					.get(getActivePlant, {headers: {Authorization: `Bearer ${token}`}})
					.then((result) => {
						let activePlant = result.data;
						activePlant = activePlant.trim();
						setcurrentPlant(activePlant);
						sessionStorage.setItem("currentPlant", activePlant);
					})
					.catch((error) => {
				console.error(error, "Getting active plant failed request:", error);
			});
		})();
	}, []);

	const onValueChanged = (e) => {
		setcurrentPlant(e.value);
		setCustomDataSource(
			store({
				url: `${appInfo.demandURL}BoxLabelPrinting?plantid=${e.value}`
			})
		);

		sessionStorage.setItem("currentPlant", e.value);
	};

	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}

	return (
		<React.Fragment>
			<div>
				<div className='header-left-side'>
					<div className='header'>
						<h2>Box Label Printing</h2>
					</div>
				</div>
				<div className='header-right-side'>
					<SelectBox
						elementAttr={{
							id: "BoxLabelPrintingSelector"
						}}
						placeholder='Plant Code'
						items={plants}
						onValueChanged={onValueChanged}
						value={currentPlant}
						displayExpr='value'
						valueExpr='id'
					/>
				</div>
				<BoxLabelPrintingGrid customDataSource={customDataSource} plantId={currentPlant} />
			</div>
		</React.Fragment>
	);
};
