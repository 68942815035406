let environment = process.env.REACT_APP_ENVIRONMENT,
  build = process.env.REACT_APP_BUILD_ID;

export default {
  title: 'Finished Goods Shipping Systems',
  environment: environment,
  build: build,
  configURL: process.env.REACT_APP_CONFIGURATION_URL,
  demandURL: process.env.REACT_APP_DEMAND_PRINTING_URL,
  authURL: process.env.REACT_APP_AUTHENTICATION_URL,
  reportURL: process.env.REACT_APP_INVENTORY_REPORT_URL,
  shippingURL: process.env.REACT_APP_SHIPPING_PLAN_URL,
  doiURL: process.env.REACT_APP_DOI_URL,
  reworkURL: process.env.REACT_APP_REWORK_URL,
  importURL: process.env.REACT_APP_IMPORT_URL
};
