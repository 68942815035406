import React from "react";
import "./home.scss";

export default () => (
	<React.Fragment>
		<img
			src={"../../../FGSS_Image.png"}
			alt="Sumitomo Homepage"
			width={"100%"}
			height={"100%"}
		/>
	</React.Fragment>
);
