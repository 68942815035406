import React, {useState, useEffect, useRef, useCallback} from "react";
import store from "../../components/hooks/store";
import DataGrid, {
	Column,
	Editing,
	Paging,
	FilterRow,
	SearchPanel,
	Toolbar,
	Item,
	Pager,
	Export,
	Scrolling,
	Sorting,
	RequiredRule,
	PatternRule,
	LoadPanel,
	Lookup,
} from "devextreme-react/data-grid";
import {CheckBox} from "devextreme-react/check-box";
import Button from "devextreme-react/button";
import {getPermission, getMode, setMode} from "./helpers/globalFunctions";
import appInfo from "../../app-info";
import {Redirect} from "react-router-dom";
import {alert, confirm} from "devextreme/ui/dialog";
import axios from "axios";
import {exportDataGrid} from "devextreme/excel_exporter";
import saveAs from "file-saver";
import {Workbook} from "exceljs";
import IconFilter from "../../components/filter.svg";

const params = {
	url: `${appInfo.configURL}HarnessLabelFormatMaster`,
};

export default () => {
	const allowedPageSizes = [10, 30, 50, "all"];
	let finalMsg = "";
	let canceled;
	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let token = currentUser ? currentUser.data.token : '';
	let language = currentUser ? currentUser.data.userData.language : '';
	const permission = getPermission(
		currentUser,
		"ConfigurationMasters",
		"HarnessLabelFormatMaster"
	);
	const dataGrid = useRef(null);
	const CustomDataSource = store(params);
	const [totalCount, setTotalCount] = useState(0);
	const [custGroupOptions, setCustGroupOptions] = useState(null);
	const [barcodesOptions, setBarcodesOptions] = useState(null);
	const [formatLabelOptions, setFormatLabelOptions] = useState(null);
	const [dataGridSettings, setdataGridSettings] = useState({
		selectTextOnEditStart: true,
		startEditAction: "click",
		showHeaderFilter: true,
		showPageSizeSelector: true,
		showFilterRow: true,
		showInfo: true,
		showNavButtons: true,
		viewmode: true,
		infiniteScroll: "standard",
	});
	useEffect(() => {
		let getCustGroups = `${params.url}/GetCustGroups`;
		let getBarcodes = `${params.url}/GetBarCodeTypes`;
		let getformatLabels = `${params.url}/GetFormatLabels`;
		(async () => {
			try {
				await axios
					.get(getformatLabels, {headers: {Authorization: `Bearer ${token}`}})
					.then((result) => {
						setFormatLabelOptions(result.data);
					});
			} catch (error) {
				console.error("Harness Label Format Master fail get format Labels");
			}
			try {
				await axios
					.get(getBarcodes, {headers: {Authorization: `Bearer ${token}`}})
					.then((result) => {
						setBarcodesOptions(result.data);
					});
			} catch (error) {
				console.error("Harness Label Format Master fail get barcodes");
			}
			try {
				await axios
					.get(getCustGroups, {headers: {Authorization: `Bearer ${token}`}})
					.then((result) => {
						setCustGroupOptions(result.data);
					});
			} catch (error) {
				console.error("Harness Label Format Master fail get custom groups");
			}
		})();
	}, []);
	/* ----- Initial Setup ----- */
	const clearFilters = () => {
		dataGrid.current.instance.clearFilter();
	};
	const getTotalCount = () => {
		let total = sessionStorage.getItem("totalCount");
		setTotalCount(total);
	};

	const onEditCanceling = async (e) => {
		if (canceled) {
			return;
		} else {
			e.cancel = true;
			const dialogResult = await confirm(
				"Unsaved data exist. <br/> Are you sure you want to cancel?"
			);
			if (dialogResult) {
				canceled = true;
				e.component.cancelEditData();
				canceled = false;
			}
		}
	};
	const onEditCanceled = (e) => {
		setMode("View");
	};

	/* ---- Functional Settings ------ */
	const onRowUpdating = (e) => {
		/* If the following fields are null set value = 0 */
		if (e.newData.serialNoLength === null) {
			e.newData.serialNoLength = 0;
		}
		if (e.newData.paperFeed === null) {
			e.newData.paperFeed = 0;
		}
		if (e.newData.darkness === null) {
			e.newData.darkness = 0;
		}
		e.newData = [{...e.oldData, ...e.newData}];
	};
	const onRowUpdated = (e) => {
		let localResultMsg = "";
		let message = e.data.data.message;
		if (e.data.statusText == "OK") {
			//e.data.status == 200
			finalMsg !== "" && !finalMsg.indexOf(message)
				? (localResultMsg += "")
				: (localResultMsg = message);
		} else {
			finalMsg !== "" && !finalMsg.indexOf(message)
				? (localResultMsg += "")
				: (localResultMsg = message);
		}
		finalMsg += localResultMsg;
	};
	const onRowInserting = (e) => {
		if (e.data.serialNoLength === null) {
			e.data.serialNoLength = 0;
		}
		if (e.data.paperFeed === null) {
			e.data.paperFeed = 0;
		}
		if (e.data.darkness === null) {
			e.data.darkness = 0;
		}
		e.data = [e.data];
	};
	const onRowInserted = (e) => {
		let localResultMsg = "";
		let message = e.data.data.message;

		if (e.data.statusText == "OK") {
			//e.data.status == 200
			finalMsg !== "" && !finalMsg.indexOf(message) && message !== undefined
				? (localResultMsg += "")
				: (localResultMsg = message);
		} else {
			finalMsg !== "" && !finalMsg.indexOf(message) && message !== undefined
				? (localResultMsg += "" + message)
				: (localResultMsg = message);
		}
		finalMsg += localResultMsg;
	};
	const onRowRemoving = (e) => {
		var changes = e.component.option("editing.changes");
		changes.find((c) => c.key === e.key).key = [e.data];
		e.component.option("editing.changes", changes);
	};
	const onRowRemoved = (e) => {
		// Customized getDeleteMsg in store in order to get msg from server
		let response = null;
		let localResultMsg = "";
		response = dataGrid.current.instance.getDataSource().store().getDeleteMsg();
		if (response && Object.keys(response).length !== 0) {
			let message = response.data.message;
			if (response.statusText == "OK") {
				finalMsg !== "" &&
				finalMsg.indexOf(message) !== -1 &&
				message !== undefined
					? (localResultMsg += "")
					: (localResultMsg = message);
			} else {
				finalMsg !== "" &&
				finalMsg.indexOf(message) !== -1 &&
				message !== undefined
					? (localResultMsg += "")
					: (localResultMsg = message);
			}
		}
		finalMsg += localResultMsg;
	};
	const onSaving = (e) => {
		finalMsg = "";
		e.promise = confirm("Are you sure you want to save?").then((response) => {
			e.cancel = !response;
		});
	};
	const onSaved = (e) => {
		finalMsg = finalMsg.replace(/,/g, "<br/>");
		if (finalMsg !== "") {
			alert(finalMsg);
		}
		dataGrid.current.instance.getDataSource().store().resetResponseMsg();
		setMode("View");
	};
	const onExporting = (e) => {
		/* Get today Date First */
		let today = new Date();
		let month = today.getMonth() + 1; //months from 1-12
		let day = today.getDate();
		let year = today.getUTCFullYear();
		let buildTitle;
		language === "ES"
			? (buildTitle = `Harness Label Format Master ${day}-${month}-${year}.xlsx`)
			: (buildTitle = `Harness Label Format Master ${month}-${day}-${year}.xlsx`);
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet(buildTitle);

		exportDataGrid({
			component: e.component,
			worksheet: worksheet,
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(
					new Blob([buffer], {type: "application/octet-stream"}),
					buildTitle
				);
			});
		});
		e.cancel = true;
	};

	const onOptionChanged = useCallback((e) => {
		if (e.fullName !== "editing.changes") {
			return;
		}

		if (getMode() === "View") {
			setMode("Edit");
		}
	}, []);

	const onEditorPreparing = (e) => {
		//custom Items
		if (e.dataField === "labelFormat" && e.parentType === "dataRow") {
			e.editorOptions.disabled = !e.row.isNewRow;
		}
	};

	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}
	return (
		<React.Fragment>
			<h2 className={"content-block"}>Harness Label Format Master</h2>
			<div id="data-grid-demo" className={"content-block"}>
				<DataGrid
					elementAttr={{
						id: "boxLabelFormatDataGrid",
					}}
					ref={dataGrid}
					dataSource={CustomDataSource}
					allowColumnResizing={true}
					columnResizingMode="nextColumn"
					columnAutoWidth={true}
					columnMinWidth={100}
					showBorders={true}
					remoteOperations={true}
					onExporting={onExporting}
					onContentReady={getTotalCount}
					onEditCanceling={onEditCanceling}
					onEditCanceled={onEditCanceled}
					onRowUpdating={onRowUpdating}
					onRowUpdated={onRowUpdated}
					onRowInserting={onRowInserting}
					onRowInserted={onRowInserted}
					onRowRemoving={onRowRemoving}
					onRowRemoved={onRowRemoved}
					onSaving={onSaving}
					onSaved={onSaved}
					onOptionChanged={onOptionChanged}
					onEditorPreparing={onEditorPreparing}>
					<Toolbar>
						<Item location="after">
							<div className={"total-label"}>
								<span>Total Records: </span>
								<label>{totalCount}</label>
							</div>
						</Item>
						<Item location="after">
							<div className={"total-label"}>
								<span> Mode: </span>
								<label id="modeLabel">View</label>
							</div>
						</Item>
						<Item>
							<Button
								icon={IconFilter}
								className="dx-toolbar-item"
								hint="Clean Filters"
								onClick={clearFilters}
							/>
						</Item>
						<Item name="exportButton" />
						<Item name="addRowButton" />
						<Item name="revertButton" />
						<Item name="saveButton" />
						<Item name="searchPanel" />
					</Toolbar>
					<LoadPanel enabled={true} />
					<Sorting mode="multiple" />
					<FilterRow
						visible={true}
						applyFilter={{key: "auto", name: "Immediately"}}
					/>
					<SearchPanel visible={true} width={240} placeholder="Search..." />
					<Export enabled={true} />
					<Editing
						mode="batch"
						startEditAction={dataGridSettings.startEditAction}
						allowAdding={permission ? permission.create : false}
						allowUpdating={permission ? permission.update : false}
						allowDeleting={ permission ? permission.delete : false}
						texts={{saveAllChanges: "", cancelAllChanges: "", addRow: ""}}
						refreshMode="full"
					/>
					<Column
						dataField="id"
						caption="No"
						allowEditing={false}
						cssClass="cell-bold"
						visible={false}
						allowFiltering={false}
					/>
					<Column
						dataField="labelFormat"
						caption="Label Format"
						cssClass="cell-bold"
						allowEditing={true}
						editorOptions={{maxLength: 6}}>
						<RequiredRule />
						<Lookup
							dataSource={formatLabelOptions}
							displayExpr="value"
							valueExpr="value"
						/>
					</Column>
					<Column
						dataField="reportName"
						editorOptions={{maxLength: 50}}
						caption="Report File Name">
						<RequiredRule />
					</Column>
					<Column
						dataField="custGroup"
						caption="Cust Group"
						allowEditing={true}
						editorOptions={{maxLength: 50}}>
						<RequiredRule />
						<Lookup
							dataSource={custGroupOptions}
							displayExpr="value"
							valueExpr="value"
						/>
					</Column>
					<Column  dataField="barcodeType" caption="Barcode Type">
						<Lookup
							dataSource={barcodesOptions}
							displayExpr="value"
							valueExpr="value"
						/>
					</Column>
					<Column
						dataField="serialNoLength"
						caption="Serial No. Length"
						alignment="left">
						<PatternRule
							pattern={/\b([0-9]|[1-9][0-9]|[1-9][0-9][0-9])\b/}
							message={"Range value is from 0 to 999"}
						/>
					</Column>
					<Column
						dataField="paperFeed"
						caption="Feed paper"
						dataType="number"
						editorOptions={{min: 0, max: 999}}
						alignment="left"></Column>
					<Column
						dataField="darkness"
						caption="Darkness"
						dataType="number"
						editorOptions={{min: -10, max: 10}}
						alignment="left"></Column>
					<Column
						dataField="speed"
						caption="Speed"
						dataType="number"
						editorOptions={{min: 2, max: 10}}
						alignment="left">
						<RequiredRule />
					</Column>
					<Column dataField="enableSpeed" caption="Enable Speed">
						<CheckBox></CheckBox>
					</Column>
					<Column dataField="checkDigit" caption="Check Digit Flg">
						<CheckBox></CheckBox>
					</Column>
					<Column
						dataField="seqnoType"
						caption="Seq No Type"
						editorOptions={{maxLength: 2}}></Column>
					<Column
						dataField="remarks"
						caption="Remarks"
						editorOptions={{maxLength: 50}}></Column>
					<Column
						dataField="attributE3"
						caption="Attribute3"
						editorOptions={{maxLength: 50}}></Column>
					<Column
						dataField="attributE4"
						caption="Attribute4"
						editorOptions={{maxLength: 50}}></Column>
					<Paging defaultPageSize={10} defaultPageIndex={0} />
					<Pager
						visible={true}
						allowedPageSizes={allowedPageSizes}
						displayMode="full"
						showPageSizeSelector={dataGridSettings.showPageSizeSelector}
						showInfo={dataGridSettings.showInfo}
						showNavigationButtons={dataGridSettings.showNavButtons}
					/>
					<Scrolling mode={dataGridSettings.infiniteScroll} rowRenderingMode='virtual'/>
				</DataGrid>
			</div>
		</React.Fragment>
	);
};
