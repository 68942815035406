import React, {useState, useRef} from "react";
import store from "../../components/hooks/store";
import {getPermission} from "../configuration/helpers/globalFunctions";
import appInfo from "../../app-info";
import {Redirect} from "react-router-dom";
import DOIMaintenanceGrid from "./DOIMaintenanceGrid";
import RadioGroup from "devextreme-react/radio-group";
import {TextBox} from "devextreme-react";
import {Button} from "devextreme-react/button";

export default () => {
	const controlNo = useRef(null);

	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	const permission = getPermission(currentUser, "DOI", "DOIMaintenance");
	const reportOptions = ["Reserve", "Scrap", "Reverse"];
	const [reportOptionSelected, setReportOptionSelected] = useState(reportOptions[0]);
	const [disableAdd, setDisableAdd] = useState(true);
	const [controlNoValue, setControlNoValue] = useState();
	const [disableFields, setDisableFields] = useState(false);
	const [customDataSource, setCustomDataSource] = useState(
		store({
			url: `${appInfo.doiURL}DOIMaintenance/`
		})
	);

	const controNoOnChange = (e) => {
		if (e.value != "") {
			setDisableAdd(false);
		} else {
			setDisableAdd(true);
		}
		e.promise = setControlNoValue(e.value);
	};

	const reportOptionOnChange = (e) => {
		e.promise = setReportOptionSelected(e.value);
	};

	if (!permission.read) {
		return <Redirect to={"/home"} />;
	}

	const clearFields = () => {
		setReportOptionSelected(reportOptions[0]);
		setControlNoValue();
		controlNo.current.instance.reset();
		sessionStorage.removeItem("tempID");
		setDisableFields(false);
		setDisableAdd(true);
	};

	const stateUp = (stateValue) => {
		setDisableFields(stateValue);
	};
	const clearGrid = (stateValue) => {
		if (stateValue == true) {
			setCustomDataSource(
				store({
					url: `${appInfo.doiURL}DOIMaintenance/`
				})
			);
		}
	};

	const clearControlNo = (stateValue) => {
		if (stateValue == true) {
			clearFields();
		}
	};

	return (
		<React.Fragment>
			<div>
				<div className='header-left-side'>
					<div className='header'>
						<h2>DOI Maintenance</h2>
					</div>
				</div>

				<form id='PrintingDetails' className={"printing-details"}>
					<div className='dx-fieldset'>
						<br />
						<RadioGroup
							items={reportOptions}
							value={reportOptionSelected}
							onValueChanged={reportOptionOnChange}
							layout='horizontal'
						/>

						<div className='dx-field'>
							<div className='dx-field-value'>
								<TextBox
									placeholder='Control No'
									elementAttr={{
										id: "Control No"
									}}
									ref={controlNo}
									disabled={disableFields}
									value={controlNoValue}
									onValueChanged={controNoOnChange}
									showClearButton={true}
									maxLength={15}
								/>
							</div>
						</div>
						<div className='dx-field'>
							<div className='dx-field-value'>
								<Button
									icon='clear'
									elementAttr={{id: "ANScancelButton"}}
									hint='Clear ControlNo'
									onClick={clearFields}
								/>
							</div>
						</div>
					</div>
				</form>
				<DOIMaintenanceGrid
					customDataSource={customDataSource}
					reportOptionSelected={reportOptionSelected}
					controlNo={controlNoValue}
					disableAdd={disableAdd}
					stateUp={stateUp}
					clearControlNo={clearControlNo}
					clearGrid={clearGrid}
				/>
			</div>
		</React.Fragment>
	);
};
