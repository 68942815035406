import React, {useState, useEffect, useRef} from "react";
import store from "../../components/hooks/store";
import {Button} from "devextreme-react/button";
import {alert, confirm} from "devextreme/ui/dialog";
import SelectBox from "devextreme-react/select-box";
import appInfo from "../../app-info";
import {Tooltip} from "devextreme-react/tooltip";
import DeleteReinspectedBox from "./DeleteReinspectedBoxGrid";
import {TextBox} from "devextreme-react";

export default () => {
	const [disableSearchBtn, setdisableSearchBtn] = useState(true);
	const [customDataSource, setCustomDataSource] = useState(null);

	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let token = currentUser.data.token;

	//   // /* Instance components in order to reset them when necessary */
	const plantSelectBox = useRef(null);

	const [activePlant, setActivePlant] = useState(null); // this one will be used in case user remove the filters;
	const boxLabelOld = useRef(null);
	const boxLabelNew = useRef(null);

	const [params, setParams] = useState();
	const [plants, setPlants] = useState(null);
	const [plantIDSelected, setPlantIDSelected] = useState();
	const [gridBoxValue, setGridBoxValue] = useState();
	const [disableFilters, setDisableFilters] = useState(false);
	const [firstTimeLoading, setfirstTimeLoading] = useState(true);
	const [boxLabelNewValue, setBoxLabelNewValue] = useState("");
	const [reportDetail, setReportDetail] = useState({
		plantId: "",
		boxLabelOld: "",
		boxLabelNew: ""
	});
	const reportParamsArr = ["plantId", "boxLabelOld", "boxLabelNew"];
	const [showTootlTip, setShowTooltip] = useState(true);
	useEffect(() => {
		if (boxLabelNewValue !== "" && boxLabelNewValue !== undefined) setdisableSearchBtn(false);
	}, [boxLabelNewValue]);

	useEffect(() => {
		let getPlants = `${appInfo.reworkURL}DeleteReInspectedBox/Plants`;
		let getActivePlant = `${appInfo.reworkURL}DeleteReInspectedBox/ActivePlant`;
		(async () => {
			fetch(getPlants, {
				headers: {Authorization: `Bearer ${token}`}
			})
				.then((result) => result.json())
				.then((result) => {
					let customPlant = result.map((plant) => {
						return {
							id: plant.Id,
							value: plant.Id + " - " + plant.Value
						};
					});
					setPlants(customPlant);
				})
				.catch((err) => {
					console.warn(err);
				});
			try {
				await fetch(getActivePlant, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`}
				})
					.then((result) => result.json())
					.then((result) => {
						setPlantIDSelected(result[0].Id);
						setReportDetail((oldValues) => {
							return {...oldValues, plantId: result[0].Id};
						});
						setParams((oldParams) => {
							return {...oldParams, plantId: result[0].Id};
						});
						setPlantIDSelected(result[0].Id);
						setActivePlant(result[0].Id);
					});
			} catch (error) {
				console.error("Delete Re-inspected Box fail getting active plant");
			}
		})();
	}, []);

	const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";

	const sendReportRequest = async () => {
		let params = "?";
		if (reportDetail.boxLabelOld === "" && reportDetail.boxLabelNew === "") {
			alert("[20001] Cannot Be Blank");
			return;
		}
		reportParamsArr.forEach(function (i) {
			if (i in reportDetail && isNotEmpty(reportDetail[i])) {
				params += `${i}=${JSON.stringify(reportDetail[i])}&`;
			}
		});

		params = params.replace(/"/g, "");
		params = params.slice(0, -1);
		setCustomDataSource(store({url: `${appInfo.reworkURL}DeleteReInspectedBox${params}`}));
		setShowTooltip(false);
		setfirstTimeLoading(false);
	};
	const setReportParams = async (e) => {
		let fieldName = e.element.id;
		let currentFieldVal = e.value;

		switch (fieldName) {
			// case "mfgPlant":
			// 	if (currentFieldVal !== "" && currentFieldVal !== null) {
			// 		setReportDetail((oldValues) => {
			// 			return {...oldValues, plantId: currentFieldVal};
			// 		});
			// 	} else {
			// 		setReportDetail((oldValues) => {
			// 			return {...oldValues, plantId: ""};
			// 		});
			// 	}
			// 	break;
			case "boxLabelOld":
				if (currentFieldVal !== "" && currentFieldVal !== null) {
					setReportDetail((oldValues) => {
						return {...oldValues, boxLabelOld: currentFieldVal};
					});
					setParams((oldParams) => {
						return {...oldParams, boxLabelOld: currentFieldVal};
					});
					//  **** INFO: validate OldBoxValue/NewBoxValue exist **** //
					try {
						await fetch(
							`${appInfo.reworkURL}DeleteReInspectedBox/BoxSno?plantId=${reportDetail.plantId}&boxLabelOld=${currentFieldVal}`,
							{
								method: "get",
								headers: {Authorization: `Bearer ${token}`}
							}
						)
							.then((result) => result.json())
							.then((result) => {
								if (result.newBoxLabel) {
									setBoxLabelNewValue(result.newBoxLabel);
								} else if (result.message) {
									alert(result.message);
								}
							});
					} catch (error) {
						// console.warn();
					}
				} else {
					setReportDetail((oldValues) => {
						return {...oldValues, boxLabelOld: currentFieldVal};
					});
				}
				break;
			case "boxLabelNew":
				if (currentFieldVal !== "" && currentFieldVal !== null) {
					// **** INFO: validate NewBoxValue is valid **** //
					setReportDetail((oldValues) => {
						return {...oldValues, boxLabelNew: currentFieldVal};
					});
					setParams((oldParams) => {
						return {...oldParams, boxLabelNew: currentFieldVal};
					});
				} else {
					setReportDetail((oldValues) => {
						return {...oldValues, boxLabelNew: currentFieldVal};
					});
				}
				break;
		}
	};
	const onValueChanged = (e) => {
		setReportDetail((oldValues) => {
			return {...oldValues, plantId: e.value};
		});
	};
	const clearFilters = () => {
		sessionStorage.setItem("totalCount", 0);
		boxLabelOld.current.instance.reset();
		setBoxLabelNewValue("");
		setCustomDataSource(null);
		setPlantIDSelected(activePlant);
		setDisableFilters(false);
		setfirstTimeLoading(true);
	};

	return (
		<React.Fragment>
			<div>
				<div className='header-left-side'>
					<div className='header'>
						<h2>Delete Re-inspected Box</h2>
					</div>
				</div>
				<form id='PrintingDetails' className={"printing-details"}>
					<div className='dx-fieldset'>
						<div className='dx-field'>
							<div className='dx-field-value'>
								<SelectBox
									width='280px'
									valueExpr='id'
									displayExpr='value'
									searchEnabled={true}
									ref={plantSelectBox}
									items={plants}
									value={plantIDSelected}
									elementAttr={{
										id: "mfgPlant"
									}}
									placeholder='Mfg. Plant'
									disabled={disableFilters}
									showClearButton={true}
									onValueChanged={onValueChanged}
								/>
								{/* <DropDownBox
									placeholder='Mfg. Plant'
									ref={plantSelectBox}
									deferRendering={false}
									valueExpr='Id'
									elementAttr={{
										id: "mfgPlant"
									}}
									displayExpr={displayExprPlant}
									dataSource={ds}
									disabled={disableFilters}
									width='310px'
									opened={isDropDownOpened}
									value={gridBoxValue}
									contentRender={contentRender}
									onOptionChanged={onOptionChanged}
									onValueChanged={onValueChangeDropDown}
								/> */}
							</div>
						</div>
						<div className='dx-field'>
							<div className='dx-field-value'>
								<TextBox
									placeholder='Box Label (Old)'
									ref={boxLabelOld}
									readOnly={disableFilters}
									elementAttr={{
										id: "boxLabelOld"
									}}
									onValueChanged={setReportParams}
									showClearButton={true}
									maxLength={15}
								/>
							</div>
						</div>
						<div className='dx-field'>
							<div className='dx-field-value'>
								<TextBox
									placeholder='Box Label (New)'
									ref={boxLabelNew}
									elementAttr={{
										id: "boxLabelNew"
									}}
									readOnly={true}
									value={boxLabelNewValue}
									onValueChanged={setReportParams}
									showClearButton={true}
									maxLength={15}
								/>
							</div>
						</div>

						<div className='dx-field'>
							<div className='dx-field-value'>
								<Button
									icon='find'
									elementAttr={{
										id: "ANSfindButton"
									}}
									disabled={disableSearchBtn}
									onClick={sendReportRequest}
								/>
								<Tooltip target='#ANSfindButton' position='bottom'>
									<div>* Click on Search Icon to get Results </div>
								</Tooltip>
							</div>
						</div>
						<div className='dx-field'>
							<div className='dx-field-value'>
								<Button icon='clear' elementAttr={{id: "ANScancelButton"}} onClick={clearFilters} />
							</div>
						</div>
					</div>
				</form>
				<DeleteReinspectedBox
					customDataSource={customDataSource}
					firstTimeLoading={firstTimeLoading}
					params={params}
				/>
			</div>
		</React.Fragment>
	);
};
