import React, {useState, useRef, useCallback} from "react";
import store from "../../components/hooks/store";
import DataGrid, {
	Column,
	Editing,
	Paging,
	FilterRow,
	Toolbar,
	ColumnFixing,
	Item,
	SearchPanel,
	Pager,
	Scrolling,
	Sorting
} from "devextreme-react/data-grid";
import CheckBox from "devextreme-react/check-box";
import Button from "devextreme-react/button";
import {getPermission, setMode, getMode} from "./helpers/globalFunctions";
import {confirm, alert} from "devextreme/ui/dialog";
import appInfo from "../../app-info";
import {Redirect} from "react-router-dom";
import IconFilter from "../../components/filter.svg";

const params = {
	url: `${appInfo.configURL}ConfigureXREFfields`
};
export default () => {
	const allowedPageSizes = [10, 30, 50, "all"];
	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let finalMsg = "";
	const permission = getPermission(currentUser, "ConfigurationMasters", "ConfigureXREFFields");

	const CustomDataSource = store(params);
	const dataGrid = useRef(null);
	let canceled = false;

	const [totalCount, setTotalCount] = useState(0);
	const [dataGridSettings, setdataGridSettings] = useState({
		selectTextOnEditStart: true,
		startEditAction: "click",
		showHeaderFilter: true,
		showPageSizeSelector: true,
		showFilterRow: true,
		showInfo: true,
		showNavButtons: true,
		infiniteScroll: "standard"
	});
	const clearFilters = () => {
		dataGrid.current.instance.clearFilter();
	};

	const onEditCanceled = (e) => {
		setMode("View");
	};
	const onEditCanceling = async (e) => {
		if (canceled) {
			return;
		} else {
			e.cancel = true;
			const dialogResult = await confirm(
				"Unsaved data exist. <br/> Are you sure you want to cancel?"
			);
			if (dialogResult) {
				canceled = true;
				e.component.cancelEditData();
				canceled = false;
			}
		}
	};
	const getTotalCount = () => {
		let currentTotal = sessionStorage.getItem("totalCount");
		setTotalCount(currentTotal);
	};
	const onRowUpdating = (e) => {
		e.newData = [{...e.oldData, ...e.newData}];
	};
	const onRowUpdated = (e) => {
		let localResultMsg = "";
		let message = e.data.data.message;
		if (e.data.statusText == "OK") {
			//e.data.status == 200
			finalMsg !== "" && !finalMsg.indexOf(message)
				? (localResultMsg += "")
				: (localResultMsg = message);
		} else {
			finalMsg !== "" && !finalMsg.indexOf(message)
				? (localResultMsg += "")
				: (localResultMsg = message);
		}
		finalMsg += localResultMsg;
	};
	const onSaving = (e) => {
		finalMsg = "";
		e.promise = confirm("Are you sure you want to save?").then((response) => {
			e.cancel = !response;
		});
	};
	const onSaved = (e) => {
		finalMsg = finalMsg.replace(/,/g, "<br/>");
		alert(finalMsg);
		dataGrid.current.instance.getDataSource().store().resetResponseMsg();
		setMode("View");
	};

	const onOptionChanged = useCallback((e) => {
		if (e.fullName !== "editing.changes") {
			return;
		}

		if (getMode() === "View") {
			setMode("Edit");
		}
	}, []);

	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}

	return (
		<React.Fragment>
			<h2 className={"content-block"}>Configuration XREF Fields</h2>
			<div id='data-grid-demo' className={"content-block"}>
				<DataGrid
					elementAttr={{
						id: "xrefConfigDataGrid"
					}}
					ref={dataGrid}
					dataSource={CustomDataSource}
					allowColumnResizing={true}
					columnResizingMode='nextColumn'
					columnMinWidth={100}
					columnAutoWidth={true}
					showBorders={true}
					remoteOperations={true}
					onContentReady={getTotalCount}
					onEditCanceled={onEditCanceled}
					onEditCanceling={onEditCanceling}
					onRowUpdating={onRowUpdating}
					onRowUpdated={onRowUpdated}
					onSaving={onSaving}
					onSaved={onSaved}
					onOptionChanged={onOptionChanged}>
					<Toolbar>
						<Item location='after'>
							<div className={"total-label"}>
								<span>Total Records: </span>
								<label>{totalCount}</label>
							</div>
						</Item>
						<Item location='after'>
							<div className={"total-label"}>
								<span> Mode: </span>
								<label id='modeLabel'>View</label>
							</div>
						</Item>
						<Item>
							<Button
								icon={IconFilter}
								className='dx-toolbar-item'
								hint='Clean Filters'
								onClick={clearFilters}
							/>
						</Item>
						<Item name='revertButton' />
						<Item name='saveButton' />
						<Item name='searchPanel' />
					</Toolbar>
					<ColumnFixing enabled={true} />
					<Sorting mode='multiple' />
					<FilterRow visible={true} applyFilter={{key: "auto", name: "Immediately"}} />
					<SearchPanel visible={true} width={240} placeholder='Search...' />
					<Editing
						mode='batch'
						startEditAction={dataGridSettings.startEditAction}
						allowUpdating={true}
						texts={{saveAllChanges: "", cancelAllChanges: ""}}
					/>
					<Column
						dataField='id'
						caption='No'
						cssClass='cell-bold'
						allowEditing={false}
						visible={false}
						allowFiltering={false}
					/>
					<Column
						dataField='xreF_FIELD'
						caption='XREF Fields'
						cssClass='cell-bold'
						allowEditing={false}
					/>
					<Column dataField='upD_IN_XREF' 
						caption='Update in XREF' 
						allowEditing={permission ? permission.update : false}>
					<CheckBox></CheckBox>
					</Column>
					<Column dataField='miX_RULE'
					 	caption='Apply Mix Rule'
						allowEditing={permission ? permission.update : false}>
						<CheckBox></CheckBox>
					</Column>
					<Column
						dataField='upD_IN_PIC'
						caption='Insert in Picture table'
					
						allowEditing={permission ? permission.update : false}>
							<CheckBox></CheckBox>
					</Column>
					<Paging defaultPageSize={10} defaultPageIndex={0} />
					<Pager
						visible={true}
						allowedPageSizes={allowedPageSizes}
						displayMode='full'
						showPageSizeSelector={dataGridSettings.showPageSizeSelector}
						showInfo={dataGridSettings.showInfo}
						showNavigationButtons={dataGridSettings.showNavButtons}
					/>
					<Scrolling mode={dataGridSettings.infiniteScroll} rowRenderingMode='virtual' />
				</DataGrid>
			</div>
		</React.Fragment>
	);
};

