import React, {useState, useRef, useEffect, useCallback} from "react";
import store from "../../components/hooks/store";
import DataGrid, {
	Column,
	Editing,
	Paging,
	FilterRow,
	Toolbar,
	Item,
	SearchPanel,
	ColumnFixing,
	Pager,
	Export,
	Scrolling,
	Sorting,
	RequiredRule,
} from "devextreme-react/data-grid";
import {CheckBox} from "devextreme-react/check-box";
import Button from "devextreme-react/button";
import {
	getPermission,
	updatePrimaryColumn,
	getMode,
	setMode,
	handleErrors,
} from "./helpers/globalFunctions";
import {confirm, alert} from "devextreme/ui/dialog";
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import saveAs from "file-saver";
import DeleteButtonCell from "./helpers/DeleteButtonCell";
import appInfo from "../../app-info";
import notify from "devextreme/ui/notify";
import {Redirect} from "react-router-dom";
import IconFilter from "../../components/filter.svg";

const params = {
	url: `${appInfo.configURL}CarrierMaster`,
};

const CustomDataSource = store(params);
let primaries = 1;
let defaultLocations = null;

export default () => {
	const allowedPageSizes = [10, 30, 50, "all"];
	const [totalCount, setTotalCount] = useState(0);
	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let token = currentUser ? currentUser.data.token : '';
	let language = currentUser ? currentUser.data.userData.language : '';
	const permission = getPermission(
		currentUser,
		"ConfigurationMasters",
		"CarrierMaster"
	);
	let getDefaultCarrier = `${appInfo.configURL}CarrierMaster/GetDefaults`;
	let finalMsg = "";
	let canceled = false;
	const dataGrid = useRef(null);

	const [dataGridSettings, setdataGridSettings] = useState({
		selectTextOnEditStart: true,
		startEditAction: "click",
		showHeaderFilter: true,
		showPageSizeSelector: true,
		showFilterRow: true,
		showInfo: true,
		showNavButtons: true,
		infiniteScroll: "standard",
	});

	useEffect(() => {
		getDefaultLocations();
	}, []);
	const getTotalCount = () => {
		let currentTotal = sessionStorage.getItem("totalCount");
		setTotalCount(currentTotal);
	};

	const onEditCanceled = useCallback((e) => {
		setMode("View");
	}, []);

	const onEditCanceling = useCallback((e) => {
		if (canceled) {
			return;
		} else {
			e.cancel = true;

			confirm(
				"Unsaved data exist. <br/> Are you sure you want to cancel?"
			).then((confirm) => {
				if (confirm) {
					canceled = true;
					e.component.cancelEditData();
					canceled = false;
				}
			});
		}
	}, []);
	const onEditorPreparing = (e) => {
		if (e.dataField === "primarY_CR" && e.parentType === "dataRow") {
			e.editorOptions.disabled = e.row.isNewRow;
		}
	};
	function getDefaultLocations() {
		try {
			fetch(getDefaultCarrier, {
				method: "get",
				headers: {Authorization: `Bearer ${token}`},
			})
				.then((response) => handleErrors(response))
				.then((response) => response.json())
				.then((result) => {
					defaultLocations = result;
				})
				.catch((error) => {
					console.warn(error);
				});
		} catch (error) {
			console.error("Carrier fail getting primary checkbox request");
		}
	}
	const onOptionChanged = useCallback((e) => {
		if (e.fullName !== "editing.changes") {
			return;
		}

		//update mode label with js when first editing.changes event fired
		if (getMode() === "View") {
			setMode("Edit");
		}

		//update primaries counter
		try {
			primaries = defaultLocations.length;

			e.value.map((c) => {
				if (c.type === "update" && c.data.hasOwnProperty("primarY_CR")) {
					if (
						defaultLocations.some((x) => x.id === c.key) &&
						!c.data.primarY_CR
					) {
						primaries--;
					}

					if (
						!defaultLocations.some((x) => x.id === c.key) &&
						c.data.primarY_CR
					)
						primaries++;
				}
			});
		} catch (error) {
			console.info(error);
		}
	}, []);
	const allowDeleting = (e) => {
		if (e.row.removed === true) {
			if (e.row.data.primarY_CR === true) {
				notify(
					{
						message: "Primary Carrier Cannot Be Deleted",
					},
					"warning",
					2000
				);
				e.component.undeleteRow(e.row.rowIndex);
			}
		}
	};
	const onRowInserting = (e) => {
		e.data = [e.data];
	};
	const onRowInserted = (e) => {
		let localResultMsg = "";
		let message = e.data.data.message;

		if (e.data.statusText == "OK") {
			//e.data.status == 200
			finalMsg !== "" && !finalMsg.indexOf(message) && message !== undefined
				? (localResultMsg += "")
				: (localResultMsg = message);
		} else {
			finalMsg !== "" && !finalMsg.indexOf(message) && message !== undefined
				? (localResultMsg += "" + message)
				: (localResultMsg = message);
		}
		finalMsg += localResultMsg;
	};
	const onRowUpdating = (e) => {
		e.newData = [{...e.oldData, ...e.newData}];
	};
	const onRowUpdated = (e) => {
		let localResultMsg = "";
		let message = e.data.data.message;
		if (e.data.statusText == "OK") {
			//e.data.status == 200
			finalMsg !== "" && !finalMsg.indexOf(message)
				? (localResultMsg += "")
				: (localResultMsg = message);
		} else {
			finalMsg !== "" && !finalMsg.indexOf(message)
				? (localResultMsg += "")
				: (localResultMsg = message);
		}
		finalMsg += localResultMsg;
	};
	const onRowRemoving = (e) => {
		var changes = e.component.option("editing.changes");
		changes.find((c) => c.key === e.key).key = [e.data];
		e.component.option("editing.changes", changes);
	};
	const onRowRemoved = (e) => {
		// Customized getDeleteMsg in store in order to get msg from server
		let response = null;
		let localResultMsg = "";
		response = dataGrid.current.instance.getDataSource().store().getDeleteMsg();

		if (response && Object.keys(response).length !== 0) {
			let message = response.data.message;
			if (response.statusText == "OK") {
				finalMsg !== "" &&
				finalMsg.indexOf(message) !== -1 &&
				message !== undefined
					? (localResultMsg += "")
					: (localResultMsg = message);
			} else {
				finalMsg !== "" &&
				finalMsg.indexOf(message) !== -1 &&
				message !== undefined
					? (localResultMsg += "")
					: (localResultMsg = message);
			}
		}
		finalMsg += localResultMsg;
	};
	const onSaving = (e) => {
		finalMsg = "";

		if (primaries > 1 || primaries === 0) {
			finalMsg += "Please select only one primary destination";
			e.cancel = true;
			alert(finalMsg);
		} else {
			e.promise = confirm("Are you sure you want to save?").then((response) => {
				e.cancel = !response;
			});
		}
	};
	const onSaved = (e) => {
		getDefaultLocations();
		finalMsg = finalMsg.replace(/,/g, "<br/>");
		alert(finalMsg);
		updatePrimaryColumn(getDefaultCarrier, token);
		dataGrid.current.instance.getDataSource().store().resetResponseMsg();
		setMode("View");
	};
	const clearFilters = () => {
		dataGrid.current.instance.clearFilter();
	};

	const onExporting = (e) => {
		/* Get today Date First */
		let today = new Date();
		let month = today.getMonth() + 1; //months from 1-12
		let day = today.getDate();
		let year = today.getUTCFullYear();
		let buildTitle;
		language === "ES"
			? (buildTitle = `Carrier Master ${day}-${month}-${year}.xlsx`)
			: (buildTitle = `Carrier Master ${month}-${day}-${year}.xlsx`);

		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet(buildTitle);

		exportDataGrid({
			component: e.component,
			worksheet: worksheet,
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(
					new Blob([buffer], {type: "application/octet-stream"}),
					buildTitle
				);
			});
		});
		e.cancel = true;
	};

	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}

	return (
		<React.Fragment>
			<h2 className={"content-block"}>Carrier Master</h2>
			<div id="data-grid-demo" className={"content-block"}>
				<DataGrid
					elementAttr={{
						id: "xrefConfigDataGrid",
					}}
					ref={dataGrid}
					dataSource={CustomDataSource}
					allowColumnResizing={true}
					columnResizingMode="nextColumn"
					columnMinWidth={120}
					columnAutoWidth={true}
					showBorders={true}
					remoteOperations={true}
					onContentReady={getTotalCount}
					onEditorPreparing={onEditorPreparing}
					onEditCanceled={onEditCanceled}
					onEditCanceling={onEditCanceling}
					onRowInserting={onRowInserting}
					onRowInserted={onRowInserted}
					onRowUpdating={onRowUpdating}
					onRowUpdated={onRowUpdated}
					onRowRemoving={onRowRemoving}
					onRowRemoved={onRowRemoved}
					onExporting={onExporting}
					onSaving={onSaving}
					onSaved={onSaved}
					onOptionChanged={onOptionChanged}>
					<Toolbar>
						<Item location="after">
							<div className={"total-label"}>
								<span>Total Records: </span>
								<label>{totalCount}</label>
							</div>
						</Item>
						<Item location="after">
							<div className={"total-label"}>
								<span> Mode: </span>
								<label id="modeLabel">View</label>
							</div>
						</Item>
						<Item>
							<Button
								icon={IconFilter}
								className="dx-toolbar-item"
								hint="Clean Filters"
								onClick={clearFilters}
							/>
						</Item>
						<Item name="exportButton" />
						<Item name="addRowButton" />
						<Item name="revertButton" />
						<Item name="saveButton" />
						<Item name="searchPanel" />
					</Toolbar>
					<ColumnFixing enabled={true} />
					<Sorting mode="multiple" />
					<FilterRow
						visible={true}
						applyFilter={{key: "auto", name: "Immediately"}}
					/>
					<SearchPanel visible={true} width={240} placeholder="Search..." />
					<Export enabled={true} />
					<Editing
						mode="batch"
						startEditAction={dataGridSettings.startEditAction}
						allowAdding={permission ? permission.create : false}
						allowUpdating={permission ? permission.update :false}
						allowDeleting={allowDeleting}
						texts={{saveAllChanges: "", cancelAllChanges: "", addRow: ""}}
						refreshMode="full"
					/>
					<Column
						dataField="id"
						caption="No"
						allowEditing={false}
						visible={false}
						cssClass="cell-bold"
						allowFiltering={false}
					/>
					<Column dataField="carrieR_ID" visible={false} />
					<Column
						dataField="carrieR_NM"
						caption="Carrier Name"
						allowEditing={true}
						editorOptions={{maxLength: 20}}>
						<RequiredRule />
					</Column>
					<Column dataField="primarY_CR" caption="Primary">
						<CheckBox></CheckBox>
					</Column>
					<Column
						width={110}
						cellRender={DeleteButtonCell}
						visible={permission ? permission.delete : false}
					/>
					<Paging defaultPageSize={10} defaultPageIndex={0} />
					<Pager
						visible={true}
						allowedPageSizes={allowedPageSizes}
						displayMode="full"
						showPageSizeSelector={dataGridSettings.showPageSizeSelector}
						showInfo={dataGridSettings.showInfo}
						showNavigationButtons={dataGridSettings.showNavButtons}
					/>
					<Scrolling mode={dataGridSettings.infiniteScroll} rowRenderingMode='virtual'/>
				</DataGrid>
			</div>
		</React.Fragment>
	);
};
