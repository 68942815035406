import {withNavigationWatcher} from "./contexts/navigation";
import {
	HomePage,
	TasksPage,
	ProfilePage,
	SystemParameters,
	ConfigureXREFFields,
	XREFMaintenance,
	PlanMaster,
	locationMaster,
	CarrierMaster,
	HarnessLabelPrinting,
	Reports,
	BoxLabelPrinting,
	Inventory,
	DestinationMaster,
	DockMaster,
	BoxLabelFormatMaster,
	HarnessLabelFormatMaster,
	LabelPrinterMaster,
	WareHouseDestinationMaster,
	WareHouseAddressMaster,
	SubAssemblyMaster,
	PartMappingMaster,
	FORDAlertMaster,
	ComponentCodeMaster,
	ShipmentReport,
	InTransitReport,
	ShipmentPlanCheckMaster,
	ReturnProductsReport,
	ImportXREFData,
	ShipmentPlanMaintenanceMaster,
	ShipmentPlanProgressReport,
	ShipmentPlanChangeLog,
	RetransmitASNCentraltoCustoms,
	ReceiptReport,
	LabelPrintedbutnotShippedReport,
	ArchiveSummary,
	PrintShipment,
	InterPlantShipmentReport,
	CycleCountReport,
	PhysicalInventoryReport,
	DOIMaintenance,
	DOIReport,
	NoboxRelabeling,
	BoxRelabeling,
	EPBMaster,
	ReturnASNReport,
	EmailParameterMaster,
	CustomerHrnLblFormatMst,
	BoxHarnessReport,
	FTPCommandMaster,
	ArchiveProcessConfiguration,
	BarcodeRuleMaster,
	RetransmitASNtoCustoms,
	AccountCalendar,
	LinkServerMaster,
	INCOMPLETESHIPMENTREPORT,
	OverShipmentTraceReport,
	DeleteReinspectedBox,
	ServerStatusConfig,
} from "./pages";

let routes = [
	{
		path: "/tasks",
		component: TasksPage,
	},
	{
		path: "/profile",
		component: ProfilePage,
	},
	{
		path: "/serverStatus",
		component: ServerStatusConfig,
	},
	{
		path: "/",
		component: HomePage,
	},
	{
		path: "/home",
		component: HomePage,
	},
	{
		path: "/reports",
		component: Reports,
	},
	{
		path: "/SystemParameters",
		component: SystemParameters,
	},
	{
		path: "/ConfigureXREFFields",
		component: ConfigureXREFFields,
	},
	{
		path: "/XREFMaintenance",
		component: XREFMaintenance,
	},
	{
		path: "/PlantMaster",
		component: PlanMaster,
	},
	{
		path: "/LocationMaster",
		component: locationMaster,
	},
	{
		path: "/CarrierMaster",
		component: CarrierMaster,
	},
	{
		path: "/HarnessLabelPrinting",
		component: HarnessLabelPrinting,
	},
	{
		path: "/InventoryReport",
		component: Inventory,
	},
	{
		path: "/BoxLabelPrinting",
		component: BoxLabelPrinting,
	},
	{
		path: "/DestinationMaster",
		component: DestinationMaster,
	},
	{
		path: "/DockMaster",
		component: DockMaster,
	},
	{
		path: "/BoxLabelFormatMaster",
		component: BoxLabelFormatMaster,
	},
	{
		path: "/HarnessLabelFormatMaster",
		component: HarnessLabelFormatMaster,
	},
	{
		path: "/LabelPrinterMaster",
		component: LabelPrinterMaster,
	},
	{
		path: "/WarehouseDestinationMaster",
		component: WareHouseDestinationMaster,
	},
	{
		path: "/WarehouseAddressMaster",
		component: WareHouseAddressMaster,
	},
	{
		path: "/SubAssemblyMaster",
		component: SubAssemblyMaster,
	},
	{
		path: "/PartMappingMaster",
		component: PartMappingMaster,
	},
	{
		path: "/FORDAlertMaster",
		component: FORDAlertMaster,
	},
	{
		path: "/ComponentCodeMaster",
		component: ComponentCodeMaster,
	},
	{
		path: "/ShipmentReport",
		component: ShipmentReport,
	},
	{
		path: "/InTransitReport",
		component: InTransitReport,
	},
	{
		path: "/ShipmentPlanCheckMaster",
		component: ShipmentPlanCheckMaster,
	},
	{
		path: "/ReturnProductsReport",
		component: ReturnProductsReport,
	},
	{
		path: "/ImportXREFData",
		component: ImportXREFData,
	},
	{
		path: "/ShipmentPlanMaintenanceMaster",
		component: ShipmentPlanMaintenanceMaster,
	},
	{
		path: "/ShipmentPlanProgressReport",
		component: ShipmentPlanProgressReport,
	},
	{
		path: "/ShipmentPlanChangeLog",
		component: ShipmentPlanChangeLog,
	},
	{
		path: "/RetransmitASNCentraltoCustoms",
		component: RetransmitASNCentraltoCustoms,
	},
	{
		path: "/ReceiptReport",
		component: ReceiptReport,
	},
	{
		path: "/LabelPrintedbutnotShippedReport",
		component: LabelPrintedbutnotShippedReport,
	},
	{
		path: "/ArchiveSummary",
		component: ArchiveSummary,
	},
	{
		path: "/PrintShipment",
		component: PrintShipment,
	},
	{
		path: "/INTERPLANTSHIPMENTREPORT",
		component: InterPlantShipmentReport,
	},
	{
		path: "/PhysicalInventoryReport",
		component: PhysicalInventoryReport,
	},
	{
		path: "/CycleCountReport",
		component: CycleCountReport,
	},
	{
		path: "/DOIMaintenance",
		component: DOIMaintenance,
	},
	{
		path: "/DOIReport",
		component: DOIReport,
	},
	{
		path: "/NoboxRelabeling",
		component: NoboxRelabeling,
	},
	{
		path: "/BoxRelabeling",
		component: BoxRelabeling,
	},
	{
		path: "/EPBMaster",
		component: EPBMaster,
	},
	{
		path: "/ReturnASNReport",
		component: ReturnASNReport,
	},
	{
		path: "/EmailParameterMaster",
		component: EmailParameterMaster,
	},
	{
		path: "/CustomerHrnLblFormatMst",
		component: CustomerHrnLblFormatMst,
	},
	{
		path: "/FTPCommandMaster",
		component: FTPCommandMaster,
	},
	{
		path: "/ArchiveProcessConfiguration",
		component: ArchiveProcessConfiguration,
	},
	{
		path: "/BarcodeRuleMaster",
		component: BarcodeRuleMaster,
	},
	{
		path: "/RetransmitASNtoCustoms",
		component: RetransmitASNtoCustoms,
	},
	{
		path: "/AccountCalendar",
		component: AccountCalendar,
	},
	{
		path: "/LinkServerMaster",
		component: LinkServerMaster,
	},
	{
		path: "/INCOMPLETESHIPMENTREPORT",
		component: INCOMPLETESHIPMENTREPORT,
	},
	{
		path: "/OverShipmentTraceReport",
		component: OverShipmentTraceReport,
	},
	{
		path: "/BoxHarnessReport",
		component: BoxHarnessReport,
	},
	{
		path: "/DeleteReinspectedBox",
		component: DeleteReinspectedBox,
	},
];

export default routes.map((route) => {
	return {
		...route,
		component: withNavigationWatcher(route.component),
	};
});
