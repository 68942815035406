import React, {useRef, useState, useCallback} from "react";
import store from "../../components/hooks/store";
import appInfo from "../../app-info";
import {getPermission, setMode, getMode} from "./helpers/globalFunctions";
import DataGrid, {
	Column,
	Editing,
	Paging,
	FilterRow,
	SearchPanel,
	Export,
	Toolbar,
	Pager,
	Scrolling,
	Sorting,
	RequiredRule,
	ColumnFixing
} from "devextreme-react/data-grid";
import {confirm, alert} from "devextreme/ui/dialog";
import Button from "devextreme-react/button";
import SelectBox from "devextreme-react/select-box";
import {Redirect} from "react-router-dom";
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import saveAs from "file-saver";
import Box, {Item} from "devextreme-react/box";
import IconFilter from "../../components/filter.svg";

export default () => {
	const [items, setItems] = useState(["Standard", "Inventory"]);
	const [optionSelected, setOptionSelected] = useState(items[0]);
	const [disableMfgColumn, setDisableMfgColumn] = useState(false);
	const [customDataSource, setCustomDataSource] = useState(
		store({
			url: `${appInfo.configURL}SystemParameters?option=${optionSelected}`
		})
	);

	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let language = currentUser ? currentUser.data.userData.language : '';
	let canceled;
	let finalMsg = "";
	const permission = getPermission(currentUser, "ConfigurationMasters", "SystemParameters");
	const [totalCount, setTotalCount] = useState(0);

	const dataGrid = useRef(null);
	const allowedPageSizes = [10, 30, 50, "all"];
	const [dataGridSettings, setdataGridSettings] = useState({
		selectTextOnEditStart: true,
		startEditAction: "click",
		showHeaderFilter: true,
		showPageSizeSelector: true,
		showFilterRow: true,
		showInfo: true,
		showNavButtons: true,
		infiniteScroll: "standard"
	});

	const onEditCanceled = useCallback((e) => {
		setMode("View");
	}, []);

	const onEditCanceling = useCallback((e) => {
		if (canceled) {
			return;
		} else {
			e.cancel = true;
			confirm("Unsaved data exist. <br/> Are you sure you want to cancel?").then((confirm) => {
				if (confirm) {
					canceled = true;
					e.component.cancelEditData();
					canceled = false;
				}
			});
		}
	}, []);
	const onEditorPreparing = (e) => {
		if (
			(e.dataField === "parameter_c" || e.dataField === "mfG_PLANT") &&
			e.parentType === "dataRow"
		) {
			e.editorOptions.disabled = !e.row.isNewRow;
		}
	};
	const getTotalCount = () => {
		let currentTotal = sessionStorage.getItem("totalCount");
		setTotalCount(currentTotal);
	};
	const onExporting = (e) => {
		/* Get today Date First */
		let today = new Date();
		let month = today.getMonth() + 1; //months from 1-12
		let day = today.getDate();
		let year = today.getUTCFullYear();

		let hours = today.getHours();
		let minutes = today.getMinutes();
		let ampm = hours >= 12 ? "pm" : "am";
		hours = hours % 12;
		hours = hours ? hours : 12; // the hour '0' should be '12'
		minutes = minutes < 10 ? "0" + minutes : minutes;
		let fullTime = hours + ":" + minutes + " " + ampm;
		let buildTitle;

		language === "ES"
			? (buildTitle = `Parameter Master ${day}-${month}-${year}.xlsx`)
			: (buildTitle = `Parameter Master ${month}-${day}-${year}.xlsx`);
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet(buildTitle);

		exportDataGrid({
			component: e.component,
			worksheet: worksheet,
			topLeftCell: {row: 1, column: 1},
			customizeCell: ({gridCell, excelCell}) => {
				if (gridCell.rowType === "header") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: {argb: "e8e9eb"}
					};
				}
			}
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], {type: "application/octet-stream"}), buildTitle);
			});
		});
		e.cancel = true;
	};
	const onSaving = (e) => {
		finalMsg = "";
		e.promise = confirm("Are you sure you want to save?").then((response) => {
			e.cancel = !response;
		});
	};
	const onRowInserting = (e) => {
		if (!e.data.parameter_c1) {
			e.data.parameter_c1 = "";
		}
		if (!e.data.parameter_c2) {
			e.data.parameter_c2 = "";
		}
		if (optionSelected === "Inventory" && !e.data.mfG_PLANT) {
			e.data.mfG_PLANT = "";
		}
		if (!e.data.parameteR_NM) {
			e.data.parameteR_NM = "";
		}
		e.data.option = optionSelected;
		e.data = [e.data];
	};
	const onRowInserted = (e) => {
		let localResultMsg = "";
		let message = e.data.data.message;

		if (e.data.statusText == "OK") {
			//e.data.status == 200
			finalMsg !== "" && !finalMsg.indexOf(message) && message !== undefined
				? (localResultMsg += "")
				: (localResultMsg = message);
		} else {
			finalMsg !== "" && !finalMsg.indexOf(message) && message !== undefined
				? (localResultMsg += "" + message)
				: (localResultMsg = message);
		}
		finalMsg += localResultMsg;
	};
	const onRowUpdating = (e) => {
		e.newData.option = e.oldData.option;
		e.newData = [{...e.oldData, ...e.newData}];
	};
	const onRowUpdated = (e) => {
		let localResultMsg = "";
		let message = e.data.data.message;
		if (e.data.statusText == "OK") {
			//e.data.status == 200
			finalMsg !== "" && !finalMsg.indexOf(message)
				? (localResultMsg += "")
				: (localResultMsg = message);
		} else {
			finalMsg !== "" && !finalMsg.indexOf(message)
				? (localResultMsg += "")
				: (localResultMsg = message);
		}
		finalMsg += localResultMsg;
	};
	const onRowRemoving = (e) => {
		var changes = e.component.option("editing.changes");
		changes.find((c) => c.key === e.key).key = [e.data];
		e.component.option("editing.changes", changes);
	};
	const onRowRemoved = (e) => {
		// Customized getDeleteMsg in store in order to get msg from server
		let response = null;
		let localResultMsg = "";
		response = dataGrid.current.instance.getDataSource().store().getDeleteMsg();
		if (response && Object.keys(response).length !== 0) {
			let message = response.data.message;
			if (response.statusText == "OK") {
				finalMsg !== "" && finalMsg.indexOf(message) !== -1 && message !== undefined
					? (localResultMsg += "")
					: (localResultMsg = message);
			} else {
				finalMsg !== "" && finalMsg.indexOf(message) !== -1 && message !== undefined
					? (localResultMsg += "")
					: (localResultMsg = message);
			}
		}
		finalMsg += localResultMsg;
	};

	const onSaved = (e) => {
		finalMsg = finalMsg.replace(/,/g, "<br/>");
		alert(finalMsg);
		dataGrid.current.instance.getDataSource().store().resetResponseMsg();
		setMode("View");
	};
	const clearFilters = () => {
		dataGrid.current.instance.clearFilter();
	};
	const customizeText = (cellInfo) => {
		let localValue = cellInfo.value;
		if (localValue) {
			localValue = localValue.toUpperCase();
			return localValue;
		}
	};

	const onOptionChanged = useCallback((e) => {
		if (e.fullName !== "editing.changes") {
			return;
		}

		if (getMode() === "View") {
			setMode("Edit");
		}
	}, []);

	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}
	const onValueChanged = (e) => {
		setOptionSelected(e.value);
		if (e.value === "Inventory") {
			setDisableMfgColumn(true);
		} else {
			setDisableMfgColumn(false);
		}
		setCustomDataSource(
			store({
				url: `${appInfo.configURL}SystemParameters?option=${e.value}`
			})
		);
	};
	return (
		<React.Fragment>
			<div>
				<div className='header-left-side'>
					<div className='header'>
						<h2>Parameter Master</h2>
					</div>
				</div>
				<div className='header-right-side'>
					<SelectBox
						elementAttr={{
							id: "SystemParametersOption"
						}}
						onValueChanged={onValueChanged}
						items={items}
						placeholder='Option'
						value={optionSelected}
					/>
				</div>

				<DataGrid
					elementAttr={{
						id: "sysParamGridContainer"
					}}
					ref={dataGrid}
					dataSource={customDataSource}
					remoteOperations={true}
					showBorders={true}
					allowColumnResizing={true}
					columnResizingMode='nextColumn'
					columnAutoWidth={true}
					columnMinWidth={150}
					repaintChangesOnly={true}
					onContentReady={getTotalCount}
					onEditorPreparing={onEditorPreparing}
					onEditCanceled={onEditCanceled}
					onEditCanceling={onEditCanceling}
					onRowInserting={onRowInserting}
					onRowInserted={onRowInserted}
					onRowUpdating={onRowUpdating}
					onRowUpdated={onRowUpdated}
					onRowRemoving={onRowRemoving}
					onRowRemoved={onRowRemoved}
					onSaving={onSaving}
					onSaved={onSaved}
					onOptionChanged={onOptionChanged}
					onExporting={onExporting}>
					<Export enabled={true} />
					<ColumnFixing enabled={true} />
					<Toolbar>
						<Item location='after'>
							<div className={"total-label"}>
								<span>Total Records: </span>
								<label>{totalCount}</label>
							</div>
						</Item>
						<Item location='after'>
							<div className={"total-label"}>
								<span> Mode: </span>
								<label id='modeLabel'>View</label>
							</div>
						</Item>
						<Item>
							<Button
								icon={IconFilter}
								className='send dx-toolbar-item'
								hint='Clean Filters'
								onClick={clearFilters}
							/>
						</Item>
						<Item name='addRowButton' />
						<Item name='revertButton' />
						<Item name='saveButton' />
						<Item name='exportButton' />
						<Item name='searchPanel' />
					</Toolbar>
					<Sorting mode='multiple' />
					<SearchPanel visible={true} width={240} placeholder='Search...' />
					<FilterRow visible={true} />
					<Editing
						mode='batch'
						allowUpdating={true}
						allowAdding={permission ? permission.create : false}
						allowDeleting={permission ? permission.delete : false}
						selectTextOnEditStart={dataGridSettings.selectTextOnEditStart}
						startEditAction={dataGridSettings.startEditAction}
						texts={{saveAllChanges: "", cancelAllChanges: ""}}
					/>
					<Column
						dataField='id'
						allowEditing={false}
						cssClass='cell-bold small-space'
						allowFiltering={false}
						visible={false}
						width={80}
					/>

					<Column
						dataField='parameter_c'
						caption='Parameter'
						customizeText={customizeText}
						cssClass='cell-bold small-space'
						editorOptions={{maxLength: 50}}>
						<RequiredRule />
					</Column>
					<Column
						dataField='parameter_c1'
						caption='Parameter 1'
						cssClass='small-space'
						customizeText={customizeText}
						editorOptions={{maxLength: 50}}></Column>
					<Column
						dataField='parameter_c2'
						caption='Parameter 2'
						cssClass='small-space'
						customizeText={customizeText}
						editorOptions={{maxLength: 50}}
					/>
					<Column
						dataField='mfG_PLANT'
						caption='Mfg Plant'
						customizeText={customizeText}
						cssClass='cell-bold small-space'
						visible={disableMfgColumn}
						editorOptions={{maxLength: 6}}
					/>
					<Column
						dataField='parameteR_NM'
						caption='Parameter Name'
						cssClass='small-space'
						customizeText={customizeText}
						editorOptions={{maxLength: 50}}
					/>
					<Column
						dataField='parameter_Value'
						caption='Parameter Value'
						cssClass='small-space'
						editorOptions={{maxLength: 50}}
						allowEditing={permission ? permission.update : false}>
						<RequiredRule />
					</Column>
					<Column
						dataField='remarks'
						caption='Remarks'
						customizeText={customizeText}
						editorOptions={{maxLength: 200}}
						allowEditing={permission ? permission.update: false}>
						<RequiredRule />
					</Column>

					<Paging defaultPageSize={10} />
					<Pager
						visible={true}
						allowedPageSizes={allowedPageSizes}
						displayMode='full'
						showPageSizeSelector={dataGridSettings.showPageSizeSelector}
						showInfo={dataGridSettings.showInfo}
						showNavigationButtons={dataGridSettings.showNavButtons}
					/>
					<Scrolling mode={dataGridSettings.infiniteScroll} rowRenderingMode='virtual' />
				</DataGrid>
			</div>
		</React.Fragment>
	);
};
