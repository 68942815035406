import React, {useState, useEffect, useRef} from "react";
import store from "../../components/hooks/store";
import SelectBox from "devextreme-react/select-box";
import DateBox from "devextreme-react/date-box";
import {Button} from "devextreme-react/button";
import RadioGroup from "devextreme-react/radio-group";
import appInfo from "../../app-info";
import {Redirect} from "react-router-dom";
import {getPermission, handleErrors} from "../configuration/helpers/globalFunctions";
import {Tooltip} from "devextreme-react/tooltip";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import InTransitReportGrid from "./InTransitReportGrid";

export default () => {
	const [disableSearchBtn, setdisableSearchBtn] = useState(false);
	var [params, setParams] = useState({
		url: `${appInfo.reportURL}InventoryReport`
	});
	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let token = currentUser ? currentUser.data.token : '';
	let formatDate = currentUser ? currentUser.data.userData.formatDate : '';
	const permission = getPermission(currentUser, "MISReports", "InTransitReport");
	const dateFromBox = useRef(null);
	const dateToBox = useRef(null);
	const currentDate = new Date();
	/* Instance components in order to reset them when necessary */
	const asnSelectBox = useRef(null);
	const plantSelectBox = useRef(null);
	const partNumSelectBox = useRef(null);
	const reportRadioBtn = useRef(null);
	const statusRadioBtn = useRef(null);

	const reportOptions = ["Summary", "Detail"];
	const statusList = ["Received", "In Transit", "Both"];
	const [reportOptionSelected, setReportOptionSelected] = useState(reportOptions[0]);
	const [statusSelected, setstatusSelected] = useState(statusList[1]);
	const [ASNNum, setASSNum] = useState(null);
	const [SEWSNum, setSEWSNum] = useState(null);
	const [maxToDate, setMaxToDate] = useState();
	const [minToDate, setMinToDate] = useState(new Date(2000, 0, 1));
	const [plantIDSelected, setPlantIDSelected] = useState();
	const [disableFilters, setDisableFilters] = useState(false);
	const [plants, setPlants] = useState(null);
	const [locationCodeList, setlocationCodeList] = useState(null);

	const [detailDGColumns, setDetailDGColumns] = useState(false);
	const [showDGColumns, setShowDGColumns] = useState(false);
	const [firstTimeLoading, setfirstTimeLoading] = useState(true);
	const [receivedDGColumns, setReceivedDGColumns] = useState(true);
	const [partTitle, setPartTitle] = useState("Cust. Part No.");
	const [reportDetail, setReportDetail] = useState({
		plantID: "",
		reportOption: "S",
		sewsPartNo: "",
		ASN: "",
		dateFrom: "",
		dateTo: ""
	});
	const reportParamsArr = ["plantID", "reportOption", "sewsPartNo", "ASN", "dateFrom", "dateTo"];
	const [showTootlTip, setShowTooltip] = useState(true);
	const [customDataSource, setCustomDataSource] = useState(null);

	useEffect(() => {
		let getPlants = `${params.url}/Plants`;
		let getLocationCodes = `${params.url}/Locations`;
		let getASNnum = `${appInfo.reportURL}InTransitReport/ASNNumbers`;
		let getSEWSnum = `${appInfo.reportURL}InTransitReport/SEWSPartNumbers`;
		(async () => {
	
				await fetch(getPlants, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`}
				})
					.then((response) => response.json())
					.then((result) => {
						let customPlant = result.map((plant) => {
							return {
								id: plant.Id,
								value: plant.Id + " - " + plant.Value
							};
						});
						setPlants(customPlant);
					})
					.catch((error) => {
						console.warn(error);
					});
		
				await fetch(getLocationCodes, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`}
				})
					.then((response) => response.json())
					.then((result) => {
						let customLocation = result.data.map((location) => {
							return location.value;
						});
						setlocationCodeList(customLocation);
					})
					.catch((error) => {
						console.warn(error);
					});
			
				await fetch(getASNnum, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`}
				})
					.then((response) => response.json())
					.then((result) => {
						let customASN = result.data.map((asn) => {
							return asn.value;
						});
						setASSNum(customASN);
					})
					.catch((error) => {
						console.warn(error);
					});
		
				await fetch(getSEWSnum, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`}
				})
					.then((result) => handleErrors(result))
					.then((response) => response.json())
					.then((result) => {
						let customSEWS = result.data.map((sews) => {
							return sews.value;
						});
						setSEWSNum(customSEWS);
					})
					.catch((error) => {
						console.warn(error);
					});
		})();
	}, []);

	const statusOnValueChange = (e) => {
		let status = e.value;
		setstatusSelected(status);
		if (status === "Received") {
			setReceivedDGColumns(true);
		}
		if (status === "In Transit") {
			setReceivedDGColumns(false);
		}
		if (status === "Both") {
			setReceivedDGColumns(true);
		}

		setShowTooltip(true);
	};
	const reportOptionOnChange = (e) => {
		e.promise = setReportOptionSelected(e.value);
		setParams({
			url: `${appInfo.reportURL}InventoryReport`
		});
		if (e.value == "Summary") {
			setReportDetail((oldValues) => {
				return {...oldValues, reportOption: "S"};
			});
			setDetailDGColumns(false);
		} else {
			//turn on flag to show extra columns
			setDetailDGColumns(true);
			setReportDetail((oldValues) => {
				return {...oldValues, reportOption: "D"};
			});
		}
	};

	const ds = new DataSource({
		store: new CustomStore({
			key: "Id",
			byKey: function (key) {
				return key;
			},
			load: () => {
				return fetch(`${appInfo.reportURL}InTransitReport/Plants`, {
					headers: {Authorization: `Bearer ${token}`}
				})
					.then((result) => result.json())
					.then((result) => {
						return result;
					})
					.catch(err => console.warn(err));
			}
		})
	});

	const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";
	const sendReportRequest = () => {
		let transitOption = "";
		if (statusSelected == "Received") {
			transitOption = "inTransitOption=R";
		} else if (statusSelected == "In Transit") {
			transitOption = "inTransitOption=T";
		} else if (statusSelected == "Both") {
			transitOption = "inTransitOption=R&inTransitOption=T";
		}

		let params = "?";
		reportParamsArr.forEach(function (i) {
			if (i in reportDetail && isNotEmpty(reportDetail[i])) {
				params += `${i}=${JSON.stringify(reportDetail[i])}&`;
			}
		});
		params = params.replace(/"/g, "");
		params = params.slice(0, -1);
		let paramsTransit = params + "&" + transitOption;
		setCustomDataSource(
			store({
				url: `${appInfo.reportURL}InTransitReport${paramsTransit}`
			})
		);
		setShowTooltip(false);
		setDisableFilters(true);
		setdisableSearchBtn(true);
		setfirstTimeLoading(false);
	};
	const setReportParams = (e) => {
		let fieldName = e.element.id;
		let currentFieldVal = e.value;

		switch (fieldName) {
			case "mfgPlant":
				if (currentFieldVal !== "" && currentFieldVal !== null) {
					setReportDetail((oldValues) => {
						return {...oldValues, plantID: currentFieldVal};
					});
				}
				break;
			case "shipmentDateFrom":
				/* set logic for getting datebox Rule not grather than 90 days */
				let fromDate = new Date(currentFieldVal).getTime();
				let maxTime = fromDate + 1000 * 3600 * 24 * 89;
				let maxDate = new Date();
				maxDate.setTime(maxTime);
				setMaxToDate(maxDate);
				setMinToDate(currentFieldVal);

				/* Validate the following scenarios: 
                        - FROM date lower than TO date
                        - FROM date <= maxDate
                        - Date range not grather than 90 days
                    */
				let toDateVal = dateToBox.current.instance.option("value");
				//calculate how many days has between from date and the current TO date selected
				let toDateTime = new Date(toDateVal).getTime();
				let differenceITime = toDateTime - fromDate; // consider from date can be too older date
				let daysPast = differenceITime / (1000 * 3600 * 24);

				if (daysPast < 0 || daysPast > 91) {
					setdisableSearchBtn(true);
				} else {
					setdisableSearchBtn(false);
				}
				if (currentFieldVal !== "") {
					setReportDetail((oldValues) => {
						return {...oldValues, dateFrom: currentFieldVal};
					});
				} else {
					setReportDetail((oldValues) => {
						return {...oldValues, dateFrom: currentFieldVal};
					});
				}
				break;
			case "shipmentDateTo":
				/* Validate TO date is not grather than FROM */
				let toDate = currentFieldVal;
				let fromDateVal = dateFromBox.current.instance.option("value");

				/* Validate the following scenarios: 
                        - TO date grather than FROM date
                        - TO date >= minDate
                        - Date range not grather than 90 days
                    */
				let toDateTime_ = new Date(toDate).getTime();
				let fromDateTime = new Date(fromDateVal).getTime();
				let differenceITime_ = toDateTime_ - fromDateTime; // consider from date can be too older date
				let daysPast_ = differenceITime_ / (1000 * 3600 * 24);

				if (daysPast_ < -1 || daysPast_ > 91) {
					setdisableSearchBtn(true);
				} else {
					setdisableSearchBtn(false);
				}

				if (currentFieldVal !== "") {
					setReportDetail((oldValues) => {
						return {...oldValues, dateTo: currentFieldVal};
					});
				} else {
					setReportDetail((oldValues) => {
						return {...oldValues, dateTo: currentFieldVal};
					});
				}
				break;
			case "asnSelect":
				if (currentFieldVal !== "") {
					setReportDetail((oldValues) => {
						return {...oldValues, ASN: currentFieldVal};
					});
				} else {
					setReportDetail((oldValues) => {
						return {...oldValues, ASN: currentFieldVal};
					});
				}
				break;
			case "sewsPartNoSelect":
				if (currentFieldVal !== "") {
					setReportDetail((oldValues) => {
						return {...oldValues, sewsPartNo: currentFieldVal};
					});
				} else {
					setReportDetail((oldValues) => {
						return {...oldValues, sewsPartNo: currentFieldVal};
					});
				}
				break;
		}
	};
	const clearFilters = () => {
		sessionStorage.setItem("totalCount", 0);
		const event = new CustomEvent("cleanFileds");
		window.dispatchEvent(event);
		setCustomDataSource(null);
		setShowTooltip(true);
		setDisableFilters(false);
		setdisableSearchBtn(false);
	};
	
	const onInitialized = (e) => {
		let fromDate = new Date(currentDate).getTime();
		let maxTime = fromDate + 1000 * 3600 * 24 * 91;
		let maxDate = new Date();
		maxDate.setTime(maxTime);
		setMaxToDate(maxDate);
		setMinToDate(currentDate);
		setReportDetail((oldValues) => {
			return {
				...oldValues,
				dateFrom: dateFromBox.current.instance
					.option("value")
					.toISOString()
					.split("T")[0]
					.replace(/-/g, "/")
			};
		});
	};
	const onInitializedDateTo = (e) => {
		setReportDetail((oldValues) => {
			return {
				...oldValues,
				dateTo: dateToBox.current.instance
					.option("value")
					.toISOString()
					.split("T")[0]
					.replace(/-/g, "/")
			};
		});
	};

	const onValueChanged = (e) => {
		setPlantIDSelected(e.value);
		setReportDetail((oldValues) => {
			return {...oldValues, plantID: e.value};
		});
	};
	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}

	return (
		<React.Fragment>
			<div>
				<div className='header-left-side'>
					<div className='header'>
						<h2>In Transit Report</h2>
					</div>
				</div>
				<form id='PrintingDetails' className={"printing-details"}>
				<div className='dx-fieldset'>
						<br />
						<div className='dx-field'>
						<div className='dx-labels'>Report Option</div>
							<RadioGroup
								items={reportOptions}
								value={reportOptionSelected}
								layout='horizontal'
								onValueChanged={reportOptionOnChange}
								ref={reportRadioBtn}
								disabled={disableFilters}
							/>
						</div>
						<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
						<div className='dx-field'>
							<RadioGroup
								items={statusList}
								value={statusSelected}
								layout='horizontal'
								ref={statusRadioBtn}
								onValueChanged={statusOnValueChange}
								disabled={disableFilters}
							/>
						</div>
					</div>
					<div className='dx-fieldset'>
						<div className='dx-field'>
							<SelectBox
								elementAttr={{
									id: "mfgPlant"
								}}
								placeholder='ASN Deliver To'
								items={plants}
								displayExpr='value'
								valueExpr='id'
								onValueChanged={onValueChanged}
								disabled={disableFilters}
								value={plantIDSelected}
							/>
						</div>
						<div className='dx-field'>
							<SelectBox
								placeholder='SEWS Part No.'
								elementAttr={{
									id: "sewsPartNoSelect"
								}}
								ref={partNumSelectBox}
								showClearButton={true}
								onValueChanged={setReportParams}
								searchEnabled={true}
								items={SEWSNum}
								disabled={disableFilters}
							/>
						</div>
						<div className='dx-field'>
							<SelectBox
								placeholder='ASN No.'
								elementAttr={{
									id: "asnSelect"
								}}
								onValueChanged={setReportParams}
								ref={asnSelectBox}
								searchEnabled={true}
								items={ASNNum}
								showClearButton={true}
								disabled={disableFilters}
							/>
						</div>
						<div className='dx-field'>
							<DateBox
								elementAttr={{
									id: "shipmentDateFrom"
								}}
								ref={dateFromBox}
								onValueChanged={setReportParams}
								useMaskBehavior={true}
								placeholder='Date From'
								displayFormat={formatDate}
								width='150px'
								dateSerializationFormat='yyyy/MM/dd'
								defaultValue={currentDate}
								onInitialized={onInitialized}
								disabled={disableFilters}
							/>
						</div>
						<div className='dx-field'>
							<DateBox
								elementAttr={{
									id: "shipmentDateTo"
								}}
								ref={dateToBox}
								defaultValue={currentDate}
								onValueChanged={setReportParams}
								useMaskBehavior={true}
								placeholder='To'
								displayFormat={formatDate}
								dateSerializationFormat='yyyy/MM/dd'
								max={maxToDate}
								min={minToDate}
								width='140px'
								onInitialized={onInitializedDateTo}
								disabled={disableFilters}></DateBox>
						</div>
						<div className='dx-field'>
							<Button
								icon='find'
								elementAttr={{
									id: "ANSfindButton"
								}}
								disabled={disableSearchBtn}
								onClick={sendReportRequest}
							/>
							<Tooltip target='#findButtonInTransit' visible={showTootlTip} position='bottom'>
								<div>* Click on Search Icon to get Results </div>
							</Tooltip>
						</div>
						<div className='dx-field'>
							<Button
								icon='clear'
								elementAttr={{
									id: "ANScancelButton"
								}}
								onClick={clearFilters}
							/>
						</div>
					</div>
				</form>
				<InTransitReportGrid
					showDGColumns={showDGColumns}
					customDataSource={customDataSource}
					firstTimeLoading={firstTimeLoading}
					receivedDGColumns={receivedDGColumns}
					detailDGColumns={detailDGColumns}
				/>
			</div>
		</React.Fragment>
	);
};
